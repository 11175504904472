import React, { useEffect, useState } from "react";
import OtpInput from 'react-otp-input';
// reactstrap components
import axios from "axios";
import moment from "moment";
import { Redirect } from "react-router-dom";
import {
	Card,
	CardBody, Col,
	Button,
	CardHeader,
	Spinner
} from "reactstrap";
import { compose } from "redux";
import { firestoreConnect } from "react-redux-firebase";
import { connect } from "react-redux";
import firebase from "firebase";
import { require2FA } from "utils/storage";
import { logout } from "../../actions/authActions"


function TwoFactorAuth({ uid, email, logout }) {

	const [otp, setOtp] = useState('')
	const [identifier, setIdentifier] = useState(null)
	const [loading, setLoading] = useState(false)
	let otpSent = false


	useEffect(() => {

		if (!otpSent && require2FA()) {
			send2FACode()
			otpSent = true
		}

	}, [])

	const inputStyles = {
		width: "2.5rem",
		height: "2.5rem",
		margin: "10px",
		fontSize: "2rem",
		borderRadius: "4px",
		border: "1px solid rgba(0, 0, 0, 0.3)"
	}



	async function send2FACode() {
		const send2FAFunction = firebase.functions().httpsCallable("send2FA")
		const { data } = await send2FAFunction({ email })
		if (data.success) {
			setIdentifier(data.data.identifier)
			alert(`OTP has been sent to your email ${email} `)
		}
	}


	async function verify2FACode() {
		setLoading(true)
		await firebase.functions().httpsCallable("validate2FA")({
			attemptedOtp: otp,
			identifier
		}).then(({ data }) => {
			if (data.success) {
				localStorage.setItem("last2FA", moment().toISOString())
				setIdentifier(null)
			}
		}).catch((error) => {
			console.log(error)
		})
	}
	if (!uid)
		return <Redirect to="/auth/login" />;
	if (!require2FA()) {
		return <Redirect to="/admin/index" />;
	}


	return (
		<Col lg="5" md="7">
			<Card className="bg-secondary shadow border-0">
				<h4 style={{ padding: "8px", marginTop: "5px", marginLeft: "7px", textDecoration: "underline" }} > Two Factor Authentication</h4>
				<CardBody className="px-lg-5 py-lg-5">
					<div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }} >
						<small className="text-center" style={{ padding: "10px" }} > We have sent you <b>One Time Password </b> to your email </small>
						<h4 className="text-center" style={{ color: "#0F9CEE" }} > Please Enter OTP</h4>
					</div>
					<div style={{ display: "flex", justifyContent: "center", padding: "20px", alignItems: "center" }} >

						<OtpInput
							value={otp}
							onChange={setOtp}
							numInputs={6}
							inputStyle={inputStyles}
						/>
					</div>
					<div style={{ display: "flex", justifyContent: "space-between" }} >
						<Button className="my-4" color="" onClick={() => {
							if (require2FA()) {
								send2FACode()
							}
						}} >
							Resend
						</Button>
						<div className="text-center">
							<Button className="my-4" color="default" onClick={verify2FACode} >
								{loading ? <Spinner /> : "Submit"}
							</Button>
						</div>

					</div>
					<div style={{ display: "flex", justifyContent: "end" }} >
						<Button color="link" onClick={() => {
							logout()
						}} >
							Logout
						</Button>
					</div>
				</CardBody>
			</Card>
		</Col>

	);

}

const mapStateToProps = state => {
	return {
		isLoad: state.auth.isLoad,
		uid: state.firebase.auth.uid,
		email: state.firebase.auth.email,
		authError: state.auth.authError,
	};
};
const mapDispatchToProps = dispatch => {
	return { logout: () => dispatch(logout()) };
};

export default compose(
	firestoreConnect([{ collection: "users" }]),
	connect(mapStateToProps, mapDispatchToProps)
)(TwoFactorAuth);
