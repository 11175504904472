import axios from "axios";
export const addConsultant = (credentials) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    getFirebase().functions().httpsCallable("sendConsultantInvitation")(
      credentials
    ).then((data) => {
      dispatch({
        type: "ADD_CONSULTANT_SUCCESS",
      });
    })
      .catch((error) => {
        console.log(error.message)
        alert(error.message)

        dispatch({
          type: "ADD_CONSULTANT_FAIL",
        });
      });
  };
};


export const deleteConsultant = (assessmentId) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    firestore
      .collection("consultants")
      .doc(assessmentId)
      .delete()
      .then((data) => {
        dispatch({
          type: "DELETE_ASSESSMENT_SUCCESS",
        });
      })
      .catch((error) => {
        dispatch({
          type: "DELETE_ASSESSMENT_FAIL",
        });
      });
  };
};

export const editConsultant = (company) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    firestore
      .collection("consultants")
      .doc(company.id)
      .update(company.payload)

      .then((data) => {
        dispatch({
          type: "EDIT_COMPANY_SUCCESS",
        });
      })
      .catch((error) => {
        dispatch({
          type: "EDIT_COMPANY_FAIL",
        });
      });
  };
};
