import authReducer from "./authReducer";
import { firebaseReducer } from "react-redux-firebase";
import { firestoreReducer } from "redux-firestore";
import { combineReducers } from "redux";
import templateReducer from "./templateReducer";

const rootReducer = combineReducers({
	auth: authReducer,
	template: templateReducer,
	firebase: firebaseReducer,
	firestore: firestoreReducer,
});
export default rootReducer;
