import Header from 'components/Headers/Header';
import React, { Component } from 'react';
import {
	Button,
	ButtonGroup,
	Card,
	CardBody,
	CardHeader,
	Col,
	Container,
	FormGroup,
	Input,
	Label,
	Modal,
	ModalBody,
	ModalHeader,
	Row,
	Spinner,
	Table,
} from 'reactstrap';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Link } from 'react-router-dom';
import { compose } from 'redux';
import { firestoreConnect, isLoaded } from 'react-redux-firebase';
import { connect } from 'react-redux';
import { convertToRaw } from 'draft-js';
import { htmlToText } from 'html-to-text';
import { resetCreated } from 'actions/templateActions';
import { deleteTemplate } from 'actions/templateActions';

class EmailTemplate extends Component {
	state = {
		modals: {
			view: false,
		},
		template: {},
		type: '',
	};
	toggleModal = name => {
		let { modals } = this.state;
		modals[name] = !modals[name];
		this.setState({ modals });
	};
	handleLanguageChange = type => {
		this.setState({
			type,
		});
	};
	componentDidMount() {
		this.props.resetCreated();
	}
	isEmailTemplateInProject = templateId => {
		let { clients } = this.props;
		if (isLoaded(clients)) {
			for (let client of clients) {
				let { projects } = client;
				if (projects !== null) {
					for (let project of projects) {
						if (project.template_id === templateId)
							return true;
					}
				}

			}
		}
		return false;
	};
	render() {
		let { templates } = this.props;
		return (
			<>
				<Header />
				{/* Page content */}
				<Container className='mt--9' fluid>
					{/* forms */}
					<div style={{ display: 'flex' }}>
						<span className='ml-auto mb-2'>
							<Link
								className='btn btn-white'
								to='/admin/email-templates/create'
							>
								Create Template
							</Link>
						</span>
					</div>

					<Row>
						<div className='col'>
							<Card className='shadow'>
								{!isLoaded(templates) ? (
									<div className='d-flex my-5 align-items-center justify-content-center'>
										<Spinner />{' '}
									</div>
								) : (
									<>
										{templates.length > 0 ? (
											<>
												<CardHeader className='border-0'>
													<h3 className='mb-0'>
														Email Templates
													</h3>
												</CardHeader>
												<Table responsive>
													<thead>
														<tr>
															<th>Title</th>
															<th>Action</th>
														</tr>
													</thead>
													<tbody>
														{templates.map(
															(template, idx) => {
																return (
																	<tr
																		key={`idxTemplate${template.id}`}
																	>
																		<td>
																			{
																				template.title
																			}
																		</td>
																		<td>


																			<Link
																				className='btn btn-primary btn-sm'
																				to={{
																					pathname:
																						'/admin/email-templates/edit',
																					state:
																						template.id,
																				}}
																			>
																				<i className='fa fa-edit'></i>
																			</Link>

																			<Button
																				size='sm'
																				color='danger'
																				disabled={this.isEmailTemplateInProject(
																					template.id
																				)}
																				onClick={e =>
																					this.props.deleteTemplate(
																						template.id
																					)
																				}
																			>
																				<i className='fa fa-trash'></i>
																			</Button>
																			<Button
																				size='sm'
																				color='dark'
																				onClick={async e => {
																					this.toggleModal(
																						'view'
																					);
																					this.setState(
																						{
																							type:
																								'',
																							template:
																								template.template,
																						}
																					);
																				}}
																			>
																				<i className='fa fa-eye'></i>
																			</Button>

																		</td>
																	</tr>
																);
															}
														)}
													</tbody>
												</Table>
											</>
										) : (
											<div className='d-flex my-5 align-items-center justify-content-center'>
												No Record Yet
											</div>
										)}
									</>
								)}
							</Card>
						</div>
					</Row>
				</Container>
				<Modal
					isOpen={this.state.modals.view}
					toggle={e => this.toggleModal('view')}
					size='lg'
				>
					<ModalHeader toggle={e => this.toggleModal('view')}>
						Template View
					</ModalHeader>
					<ModalBody>
						<FormGroup>
							<Label>Template Languauge:</Label>
							<Input
								type='select'
								className="text-capitalize"
								onChange={e =>
									this.handleLanguageChange(e.target.value)
								}
							>
								<option value='' selected hidden>
									Select Language
								</option>
								{Object.keys(this.state.template).map(key => {
									return (
										<option
											value={key}
											className='text-capitalize'
										>
											{key}
										</option>
									);
								})}
							</Input>
						</FormGroup>
						{this.state.type && (
							<>

								<FormGroup>
									<Label>Subject:</Label>
									<Input
										readOnly
										value={
											this.state.template[
											this.state.type
											]['subject']
										}
									/>
								</FormGroup>
								<FormGroup>
									<Label>Message:</Label>
									<Input
										readOnly
										type='textarea'
										rows='12'
										style={{ resize: 'none' }}
										value={htmlToText(
											this.state.template[
											this.state.type
											]['message']
										)}
									/>
								</FormGroup>
							</>
						)}
					</ModalBody>
				</Modal>
			</>
		);
	}
}
const mapStateToProps = state => {
	return {
		templates: state.firestore.ordered.templates,
		clients: state.firestore.ordered.clients,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		resetCreated: () => dispatch(resetCreated()),
		deleteTemplate: id => dispatch(deleteTemplate(id)),
	};
};
export default compose(
	firestoreConnect([{ collection: 'templates' }, { collection: 'clients' }]),
	connect(mapStateToProps, mapDispatchToProps)
)(EmailTemplate);
