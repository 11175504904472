export const addAdmin = (credentials) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();
    firebase
      .auth()
      .createUserWithEmailAndPassword(credentials.email, credentials.password)
      .then((data) => {
        return data.user.uid;
      })
      .then((uid) => {
        return firestore.collection("users").doc(uid).set({
          name: credentials.name,
          email: credentials.email,
          role: "admin",
        });
      })
      .then((data) => {
        dispatch({
          type: "ADD_ADMIN_SUCCESS",
        });
      })
      .catch((error) => {
        dispatch({
          type: "ADD_ADMIN_FAIL",
        });
      });
  };
};




export const resetEmailPassword = (email) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();

    firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then((data) => {
        alert("Password reset email sent successfully")
      })
      .catch((error) => {
        alert(error.message)
      });


  };
};

export const deleteAdmin = (assessmentId) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    firestore
      .collection("users")
      .doc(assessmentId)
      .delete()
      .then((data) => {
        dispatch({
          type: "DELETE_ASSESSMENT_SUCCESS",
        });
      })
      .catch((error) => {
        dispatch({
          type: "DELETE_ASSESSMENT_FAIL",
        });
      });
  };
};