import { addAssessments } from "actions/assessmentActions";
import Header from "components/Headers/Header";
import React, { Component } from "react";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { Link, Redirect } from "react-router-dom";
// reactstrap components
import {
	Button, Card, CardBody, CardHeader, Col, Container, Form, FormFeedback, Input, Label, Row
} from "reactstrap";
import { compose } from "redux";
import AddEditQuestion from "./AddEditQuestion";

class addAssessment extends Component {
	state = {
		Question: false,
		questions: [],
		redirect: false,
	};


	deleteQuestion = index => {
		var newQuestions = this.state.questions;
		newQuestions.splice(index, 1);
		this.setState({ questions: newQuestions });
	};
	addAssessment = async e => {
		e.preventDefault();
		var error = false;
		if (!e.target.title.value) {
			this.setState({ title_error: "Please Add a Title" });
			error = true;
		} else {
			this.setState({ title_error: "", title: e.target.title.value });
		}
		if (!e.target.description.value) {
			this.setState({ description_error: "Please Add a Description" });
			error = true;
		} else {
			this.setState({
				description_error: "",
				description: e.target.description.value,
			});
		}
		if (!e.target.status.value) {
			this.setState({ status_error: "Please add Status" });
			error = true;
		} else {
			this.setState({
				status_error: "",
				status: e.target.status.value,
			});
		}
		if (!e.target.price.value) {
			this.setState({ price_error: "Please Add some Price" });
			error = true;
		} else {
			this.setState({ price_error: "", price: e.target.price.value });
		}
		if (e.target.type.value === "Type") {
			// boat
			this.setState({ type_error: "Please Select a type" });
			error = true;
		} else {
			this.setState({ type_error: "", type: e.target.type.value });
		}
		if (!e.target.version.value) {
			this.setState({ version_error: "Please Add a Version" });
			error = true;
		} else {
			this.setState({
				version_error: "",
				version: e.target.version.value,
			});
		}

		if (!error) {
			const assessment = {
				title: e.target.title.value,
				description: e.target.description.value,
				price: e.target.price.value,
				currency: e.target.currency.value,
				type: e.target.type.value,
				version: e.target.version.value,
				questions: this.state.questions,
				status: e.target.status.value
			};
			await this.props.addAssessment(assessment);
			this.setState({
				redirect: true,
				title: "",
				title_error: "",
				description: "",
				description_error: "",
				price: "",
				price_error: "",
				type: "",
				type_error: "",
				version: "",
				version_error: "",
			});
		}
	};


	render() {
		if (this.state.redirect) {
			return <Redirect to="/admin/assessments" />;
		}
		const { uid, components } = this.props;
		if (!uid) {
			return <Redirect to="/auth/login" />;
		} else {
			return (
				<>
					<Header />
					<Container className="mt--9" fluid>
						<Row>
							<div className="col">
								<Card className=" shadow ">
									<CardHeader className=" border-0 ">
										<div style={{ display: "flex" }}>
											<Link to="/admin/assessments" className="text-default ">
												<i
													className="fas fa-long-arrow-alt-left "
													style={{ fontSize: "25px" }}
												/>
											</Link>
											<h3 className="ml-3 mb-0 ">Add Assessment</h3>
										</div>
									</CardHeader>
									<CardBody className=" mb-0 ">
										<Form onSubmit={this.addAssessment}>
											<Label>Title</Label>
											<Input
												name="title"
												valid={!this.state.title_error && this.state.title}
												invalid={this.state.title_error}
											/>
											<FormFeedback>{this.state.title_error}</FormFeedback>
											<Label> Description</Label>
											<Input
												name="description"
												type="textarea"
												valid={
													!this.state.description_error &&
													this.state.description
												}
												invalid={this.state.description_error}
											/>
											<FormFeedback>
												{this.state.description_error}
											</FormFeedback>

											<Label> Type</Label>
											<Input
												name="type"
												type="select"
												valid={!this.state.type_error && this.state.type}
												invalid={this.state.type_error}
											>
												<option hidden>Type</option>
												{components?.find(component => {
													return component.id === "assessment_type";
												})?.types.map(type => {
													return <option key={type} value={type}>{type}</option>;
												})}

											</Input>
											<FormFeedback>{this.state.type_error}</FormFeedback>
											<Label> Status</Label>
											<Input
												name="status"
												type="select"
												required
												valid={!this.state.status_error && this.state.status}
												invalid={this.state.status_error}
												default="active"
											>
												<option value="active">Active</option>
												<option value="inactive">In Active</option>
											</Input>
											<FormFeedback>{this.state.status_error}</FormFeedback>

											<Label>Version</Label>
											<Input
												name="version"
												valid={!this.state.version_error && this.state.version}
												invalid={this.state.version_error}
											/>
											<FormFeedback>{this.state.version_error}</FormFeedback>

											<Label>Price</Label>
											<Input
												name="price"
												type="number"
												min={0}
												valid={!this.state.price_error && this.state.price}
												invalid={this.state.price_error}
											/>
											<FormFeedback>{this.state.price_error}</FormFeedback>

											<Label>Currency</Label>
											<Input
												name="currency"
												type="select"
											// valid={!this.state.type_error && this.state.type}
											// invalid={this.state.type_error}
											>
												<option hidden>Currency</option>
												<option value="USD">USD</option>
												<option value="EUR">EUR</option>
											</Input>
											<FormFeedback>{this.state.type_error}</FormFeedback>

											<Button
												className="mt-2"
												type="button"
												color="default"
												size="md"
												onClick={() => {
													this.setState({
														editQuestion: {}
													})
												}}
											>
												Add Question
											</Button>
											<Row>
												<Col className="text-right">
													<Button
														className="mt-2"
														type="submit"
														color="default"
														size="md"
													>
														Save Assessment
													</Button>
												</Col>
											</Row>
										</Form>
										<AddEditQuestion
											isOpen={!!this.state.editQuestion}
											onClose={() => {
												this.setState({
													editQuestion: null
												})
											}}
											question={this.state.editQuestion}
											onQuestionChanged={(question) => {
												this.setState({
													questions: [...this.state.questions, question],
													editQuestion: null
												});

											}}
										/>
										{this.state.questions.length > 0
											? this.state.questions.map((question, index) => {
												return (
													<Row className="mt-4" key={question.id}>
														<Col xs={{ size: 8 }}>
															<h3 className=" mb-0 ">
																{question?.type == "SECTION" ? "Section" : "Question"}: {question.text}
															</h3>
															{question.help && (<p className="mb-0"> Help : {question.help}</p>)}
														</Col>
														<Col xs={{ size: 4 }} className="text-right">
															<Button
																className="mt-2"
																type="button"
																color="default"
																onClick={e => {
																	e.preventDefault();
																	this.deleteQuestion(index);
																}}
																size="md"
															>
																Delete
															</Button>
														</Col>
													</Row>
												);
											})
											: null}
									</CardBody>
								</Card>
							</div>
						</Row>
					</Container>
				</>
			);
		}
	}
}
const mapStateToProps = state => {
	return {
		uid: state.firebase.auth.uid,
		components: state.firestore.ordered.components,
	};
};
const mapDispatchToProps = dispatch => {
	return {
		addAssessment: assessment => dispatch(addAssessments(assessment)),
		// deleteCompany: (company) =>
		//   dispatch(deleteCompany(company)),
		// editCompany: (company, newCompany) =>
		//   dispatch(editCompany(company, newCompany)),
	};
};

export default compose(
	firestoreConnect([{ collection: "components" }]),
	connect(mapStateToProps, mapDispatchToProps)
)(addAssessment);
