import Header from 'components/Headers/Header';
import React, { Component } from 'react';
import {
	Alert,
	Button,
	Card,
	CardBody,
	CardHeader,
	Col,
	Container,
	Form,
	FormGroup,
	Input,
	Label,
	Row,
	Spinner,
} from 'reactstrap';

import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import Fade from 'reactstrap/lib/Fade';
import { connect } from 'react-redux';
import { createTemplate, editTemplate } from '../../actions/templateActions';
import { Redirect } from 'react-router';
import { compose } from 'redux';
import { firestoreConnect, isLoaded } from 'react-redux-firebase';
import htmlToDraft from 'html-to-draftjs';
import { CopyToClipboard } from 'react-copy-to-clipboard';

class EditEmailTemplate extends Component {
	template_id = this.props.history.location.state;
	state = {
		type: '',
		title: '',
		template: {},
		possibleVariables: [
			'*|RaterFirstname|*',
			'*|RaterLastname|*',
			'*|RaterEmail|*',
			'*|ConsultantName|*',
			'*|ConsultantCompany|*',
			'*|ClientName|*',
			'*|ProjectName|*',
			'*|AssessmentName|*',
			'*|ProjectStartDate|*',
			'*|ProjectEndDate|*',
			'*|LeaderName|*',
			'*|LeaderEmail|*',
			'*|RaterRole|*',
			'*|RaterRelationship|*',
		],
		error: false,
		error_msg: false,
	};
	componentDidUpdate(prevProps, prevState) {
		if (prevProps.rawTemplates !== this.props.rawTemplates) {
			this.setState({
				template: JSON.parse(
					JSON.stringify(
						this.props.rawTemplates[this.template_id].template
					)
				),
				title: JSON.parse(
					JSON.stringify(
						this.props.rawTemplates[this.template_id].title
					)
				),
			});
		}
	}
	onEditorStateChange = editorState => {
		let { template, type } = this.state;
		template[type]['message'] = editorState;
		this.setState({
			template,
		});
	};
	resetFields = e => {
		let { type, template } = this.state;
		template[type] = {
			subject: '',
			error: false,
			error_msg: '',
			message: EditorState.createEmpty(),
		};
		this.setState({
			template,
		});
	};
	handleChange = e => {
		let { type, template } = this.state;

		template[type][e.target.name] = e.target.value;
		this.setState({ template });
	};
	handleLanguageChange = e => {
		let { template } = this.state;
		if (e.target.value in template) {
			if (
				typeof template[e.target.value]['message'] === 'string' &&
				template[e.target.value]['message'] !== ''
			) {
				const blocksFromHtml = htmlToDraft(
					template[e.target.value]['message']
				);
				const { contentBlocks, entityMap } = blocksFromHtml;
				const contentState = ContentState.createFromBlockArray(
					contentBlocks,
					entityMap
				);
				const editorState = EditorState.createWithContent(contentState);
				// template[e.target.value]['message'] = editorState;
				let type = e.target.value;
				this.setState(prevState => {
					prevState['template'][type].message = editorState;
					return {
						...prevState,
					};
				});
			}
		} else {
			template[e.target.value] = {
				subject: '',
				message: EditorState.createEmpty(),
			};
		}
		this.setState({
			type: e.target.value,
		});
	};
	getText(html) {
		var divContainer = document.createElement('div');
		divContainer.innerHTML = html;
		return divContainer.textContent || divContainer.innerText || '';
	}
	render() {
		let { type } = this.state;
		let { template, rawTemplates } = this.props;
		let template_id = this.props.history.location.state;
		if (template.created || template_id === null)
			return <Redirect to='/admin/email-templates' />;
		return (
			<>
				<Header />
				{/* Page content */}
				<Container className='mt--9' fluid>
					<Fade
						in={this.state.error}
						onEnter={e =>
							setInterval(() => {
								this.setState({ error: false });
							}, 5000)
						}
					>
						<Alert color='danger' isOpen={this.state.error}>
							{this.state.error_msg}
						</Alert>
					</Fade>
					{/* forms */}
					{/* <div style={{ display: 'flex' }}>
						<span className='ml-auto mb-2'>
							<Button>Create Template</Button>
						</span>
					</div> */}

					<Row>
						<div className='col'>
							<Card className='shadow mb-4'>
								<CardHeader className='border-0'>
									<h3 className='mb-0'>
										Edit Email Template
									</h3>
								</CardHeader>
								<CardBody>
									{!(
										isLoaded(template) &&
										isLoaded(rawTemplates)
									) ? (
										<div className='d-flex my-5 align-items-center justify-content-center'>
											<Spinner />
										</div>
									) : (
										<>
											<FormGroup className='mt-3'>
												<Label>Title</Label>
												<Input
													name='title'
													defaultValue={
														rawTemplates[
															template_id
														].title
													}
													onChange={e =>
														this.setState({
															title: e.target
																.value,
														})
													}
												/>
											</FormGroup>
											{!isLoaded(this.props.languages) ? (
												<div className='d-flex align-items-center justify-content-center my-3'>
													<Spinner />
												</div>
											) : (
												<FormGroup>
													<Label>
														Email Type & Language
													</Label>
													<Input
														type='select'
														name='type'
														value={this.state.type}
														className='text-capitalize'
														onChange={
															this
																.handleLanguageChange
														}
													>
														<option value='' hidden>
															Email Type &
															Language
														</option>
														{this.props.languages.map(
															language => {
																return (
																	<option
																		key={language.language}
																		value={
																			language.language
																		}
																		className='text-capitalize'
																	>
																		{
																			language.language
																		}
																	</option>
																);
															}
														)}
													</Input>
													{this.state.type === '' && (
														<small className='text-danger font-weight-bold d-block mt-1'>
															* Select your
															language to update
															template
														</small>
													)}
												</FormGroup>
											)}
											{this.state.type === '' ? (
												''
											) : (
												<Form
													onSubmit={e => {
														e.preventDefault();
														let { template } = this.state;
														var error = false;

														Object.keys(template).filter(t => {
															if (typeof template[t]['message'] != 'object') {
																let blocksFromHtml =
																	htmlToDraft(template[t]['message']);
																const {
																	contentBlocks,
																	entityMap,
																} = blocksFromHtml;
																const contentState =
																	ContentState.createFromBlockArray(
																		contentBlocks,
																		entityMap
																	);
																const editorState =
																	EditorState.createWithContent(
																		contentState
																	);
																template[t][
																	'message'
																] = editorState;

															}
														});

														let obj = Object.keys(
															template
														).filter(
															t =>
																!template[
																	t
																].message
																	.getCurrentContent()
																	.hasText() &&
																template[t]
																	.subject ==
																''
														);

														if (
															obj.length ==
															Object.keys(
																template
															).length
														) {
															for (let o of obj) {
																delete template[o];
															}
															this.setState({
																type: '',
																error: true,
																error_msg:
																	'Kindly Provide Correct Information',
															});
															return;
														} else if (obj.length) {
															for (let o of obj) {
																delete template[
																	o
																];
															}
														}
														Object.keys(
															template
														).map(key => {

															let message =
																draftToHtml(
																	convertToRaw(
																		template[
																			key
																		][
																			'message'
																		].getCurrentContent()
																	)
																);
															let messageHasText =
																template[key][
																	'message'
																]
																	.getCurrentContent()
																	.hasText();
															let subject = template[key]['subject'];
															if (
																this.state
																	.title === ''
															) {
																error = true;
																window.scrollTo(
																	0,
																	0
																);
																this.setState({
																	error: true,
																	error_msg: `Kindly provide title`,
																});
															} else if (
																!messageHasText
															) {
																window.scrollTo(
																	0,
																	0
																);
																error = true;
																this.setState({
																	error: true,
																	error_msg: `Kindly provide ${key}'s message`,
																});
															} else if (
																subject === ''
															) {
																error = true;
																window.scrollTo(
																	0,
																	0
																);
																this.setState({
																	error: true,
																	error_msg: `Kindly provide ${key}'s subject`,
																});
															} else {
																let isFirstname = false;
																let isLastname = false;
																let text = this.getText(message);
																let subjectvariables = subject.match(/\*\|[a-zA-z0-9]+\|\*/g);
																if (subjectvariables != null) {
																	for (let v of subjectvariables) {
																		if (!this.state.possibleVariables.includes(v)) {
																			error = true;
																			this.setState(
																				{
																					error: true,
																					error_msg:
																						'Kindly Choose Possible Variables.',
																				}
																			);
																			window.scrollTo(
																				0,
																				0
																			);
																			return;
																		}
																	}
																	let check0 =
																		subjectvariables.includes(
																			'*|RaterFirstname|*'
																		);
																	if (
																		check0
																	) {
																		isFirstname = true;
																	}
																	let check01 =
																		subjectvariables.includes(
																			'*|RaterLastname|*'
																		);
																	if (
																		check01
																	) {
																		isLastname = true;
																	}
																}

																let messagevariables =
																	text.match(
																		/\*\|[a-zA-z0-9]+\|\*/g
																	);
																if (messagevariables != null) {
																	for (let v of messagevariables) {

																		if (!this.state.possibleVariables.includes(v)) {
																			error = true;
																			this.setState(
																				{
																					error: true,
																					error_msg:
																						'Kindly Choose Possible Variables helpppp.',
																				}
																			);
																			window.scrollTo(0, 0);
																			return;
																		}
																	}

																	let check =
																		messagevariables.includes(
																			'*|RaterFirstname|*'
																		);
																	if (check) {
																		isFirstname = true;
																	}
																	if (
																		!isFirstname
																	) {
																		error = true;
																		this.setState(
																			{
																				error: true,
																				error_msg:
																					'*|RaterFirstname|* variable is manadatory.',
																			}
																		);
																		window.scrollTo(
																			0,
																			0
																		);
																		return;
																	}
																	let check1 =
																		messagevariables.includes(
																			'*|RaterLastname|*'
																		);
																	if (
																		check1
																	) {
																		isLastname = true;
																	}
																	if (
																		!isLastname
																	) {
																		error = true;
																		this.setState(
																			{
																				error: true,
																				error_msg:
																					'*|RaterLastname|* variable is manadatory.',
																			}
																		);
																		window.scrollTo(
																			0,
																			0
																		);
																		return;
																	}

																}

																template[key][
																	'message'
																] = draftToHtml(
																	convertToRaw(
																		template[
																			key
																		][
																			'message'
																		].getCurrentContent()
																	)
																);
															}
														});
														if (!error) {
															this.props.editTemplate(
																{
																	template_id,
																	title: this
																		.state
																		.title,
																	template:
																		this
																			.state
																			.template,
																}
															);
															this.props.history.push(
																'/admin/email-templates'
															);
														}
													}}
												>
													<Label>
														<b>
															Possible Variables:
														</b>
													</Label>
													<ul>
														{this.state.possibleVariables.map(
															(variable, idx) => (
																<CopyToClipboard
																	key={idx}
																	text={
																		variable
																	}
																	onCopy={() => {
																		this.setState(
																			{
																				copied: idx,
																			}
																		);
																		window.setTimeout(
																			() => {
																				this.setState(
																					{
																						copied: -1,
																					}
																				);
																			},
																			1500
																		);
																	}}
																>
																	<li className='pointer'>
																		{
																			variable
																		}
																		<span
																			className={`ml-3 font-weight-bold text-success ${this
																				.state
																				.copied ==
																				idx
																				? 'd-inline-block'
																				: 'd-none'
																				}`}
																		>
																			Copied
																		</span>
																	</li>
																</CopyToClipboard>
															)
														)}
													</ul>

													<b>
														Note: *|Variable|*,
														Kindly use variable as
														mentioned
													</b>

													<Row>
														<Col>
															<FormGroup className='mt-3'>
																<Label>
																	Subject
																</Label>
																<Input
																	name='subject'
																	value={
																		this
																			.state
																			.template[
																			type
																		]
																			.subject
																	}
																	onChange={
																		this
																			.handleChange
																	}
																/>
															</FormGroup>
															<FormGroup className='mt-3'>
																<Label>
																	Message
																</Label>
																<div>
																	<Editor
																		editorState={
																			this
																				.state
																				.template[
																				type
																			]
																				.message
																		}
																		onEditorStateChange={
																			this
																				.onEditorStateChange
																		}
																		wrapperClassName='demo-wrapper'
																		editorClassName='demo-editor'
																		toolbarClassName='toolbar-wrapper'
																	/>
																</div>
															</FormGroup>
															<Button
																type='submit'
																color='primary'
															>
																Save
															</Button>
															<Button
																type='button'
																onClick={e =>
																	this.resetFields()
																}
															>
																Reset
															</Button>
														</Col>
													</Row>
												</Form>
											)}
										</>
									)}
								</CardBody>
							</Card>
						</div>
					</Row>
				</Container>
			</>
		);
	}
}

const mapStateToProps = state => {
	return {
		template: state.template,
		rawTemplates: state.firestore.data.templates,
		languages: state.firestore.ordered.languages,
	};
};
const mapDispatchToProps = dispatch => {
	return {
		editTemplate: templateObj => dispatch(editTemplate(templateObj)),
	};
};

export default compose(
	firestoreConnect([
		{ collection: 'templates' },
		{ collection: 'languages' },
	]),
	connect(mapStateToProps, mapDispatchToProps)
)(EditEmailTemplate);
