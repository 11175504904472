import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from 'uuid';
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import { CopyToClipboard } from 'react-copy-to-clipboard';
// reactstrap components
import {
  Button, Col, Form, FormFeedback,
  Input, Label, Modal, ModalBody, ModalHeader, Row
} from "reactstrap";


function AddEditQuestion({ isOpen, onClose, question, onQuestionChanged, components }) {
  const [currentQuestion, setCurrentQuestion] = useState(question)
  const [copied, setCopied] = useState(null)
  const [error, setError] = useState({})
  const possibleVariables = [
    '*|LeaderFirstName|*',
    '*|LeaderLastName|*',
    '*|LeaderEmail|*',
  ]

  useEffect(() => {
    setCurrentQuestion(question)
  }, [question])



  useEffect(() => {
    if (currentQuestion && !currentQuestion.id) {
      setCurrentQuestion({ ...currentQuestion, id: uuidv4() })
    }
  }, [currentQuestion])

  function addSectionComponent() {
    return <>
      <Label>Section Title</Label>
      <Input
        name="text"
        defaultValue={currentQuestion?.text}
        valid={!!!error.text_section_error && currentQuestion?.text}
        invalid={!!error.text_section_error}
        onChange={(e) => {
          setCurrentQuestion({ ...currentQuestion, text: e.target.value })
          setError((error) => {
            return { ...error, text_section_error: "" }
          })
        }}
      />
      <FormFeedback invalid="true" > {error.text_section_error}</FormFeedback>
      <Label>Section Name</Label>
      <Input
        name="sectionName"
        defaultValue={currentQuestion?.sectionName}
        valid={!!!error.section_name_error && currentQuestion?.sectionName}
        invalid={!!error.section_name_error}
        onChange={(e) => {
          setCurrentQuestion({ ...currentQuestion, sectionName: e.target.value })
          setError((error) => {
            return { ...error, section_name_error: "" }
          })
        }}
      />
      <FormFeedback invalid="true" > {error.section_name_error}</FormFeedback>
      <Label>Description</Label>
      <Input name="help"
        defaultValue={currentQuestion?.help}
        valid={!!!error.help_error && currentQuestion?.help}
        invalid={!!error.help_error}
        onChange={(e) => {
          setCurrentQuestion({ ...currentQuestion, help: e.target.value })
          setError((error) => {
            return { ...error, help_error: "" }
          })
        }}
      />
      <FormFeedback invalid="true" >{error.help_error}</FormFeedback>

    </>
  }

  function addQuestionComponent() {
    return <>



      <Label>Dimension</Label>
      <Input
        name="dimension"
        type="select"
        valid={!!!error.dimension_error && currentQuestion?.dimension}
        invalid={!!error.dimension_error}
        defaultValue={currentQuestion?.dimension}
        onChange={(e) => {
          setCurrentQuestion({ ...currentQuestion, dimension: e.target.value })
          setError((error) => {
            return { ...error, dimension_error: "" }
          })
        }}
      >
        <option hidden>Dimension</option>
        {components?.find(component => {
          return component.id === "question_dimension";
        })?.dimensions.map(dimension => {
          return <option key={dimension} value={dimension}>{dimension}</option>;
        })}

      </Input>
      <FormFeedback invalid="true" >
        {error.dimension_error}
      </FormFeedback>


      <Label>Competency</Label>
      <Input
        name="competency"
        type="select"
        valid={!!!error.competency_error && currentQuestion?.dimension}
        invalid={!!error.competency_error}
        defaultValue={currentQuestion?.competency}
        onChange={(e) => {
          setCurrentQuestion({ ...currentQuestion, competency: e.target.value })
          setError((error) => {
            return { ...error, competency_error: "" }
          })
        }}
      >
        <option value={null} hidden>Competency</option>
        {components?.find(component => {
          return component.id === "question_competency";
        })?.competencies.map(competency => {
          return <option key={competency} value={competency}>{competency}</option>;
        })}
      </Input>


      <FormFeedback invalid="true" >
        {error.competency_error}
      </FormFeedback>
      <Label>Category</Label>
      <Input
        name="category"
        type="select"
        valid={!!!error.category_error && currentQuestion?.dimension}
        invalid={!!error.category_error}
        defaultValue={currentQuestion?.category}
        onChange={(e) => {
          setCurrentQuestion({ ...currentQuestion, category: e.target.value })
          setError((error) => {
            return { ...error, category_error: "" }
          })
        }}

      >
        <option hidden>Category</option>

        {components?.find(component => {
          return component.id === "question_category";
        })?.categories.map(category => {
          return <option key={category} value={category}>{category}</option>;
        })}

      </Input>
      <FormFeedback invalid="true" >
        {error.category_error}
      </FormFeedback>



      <Label>Question Text</Label>
      <Input
        name="text"
        defaultValue={currentQuestion?.text}
        valid={!!!error.text_error && currentQuestion?.text}
        invalid={!!error.text_error}
        onChange={(e) => {
          setCurrentQuestion({ ...currentQuestion, text: e.target.value })
          setError((error) => {
            return { ...error, text_error: "" }
          })
        }}
      />

      <FormFeedback invalid="true" >{error.text_error}</FormFeedback>

      <Label>Question Help</Label>
      <Input name="help"
        defaultValue={currentQuestion?.help}
        valid={!!!error.help_error && currentQuestion?.help}
        invalid={!!error.help_error}
        onChange={(e) => {
          setCurrentQuestion({ ...currentQuestion, help: e.target.value })
          setError((error) => {
            return { ...error, help_error: "" }
          })
        }}
      />
      <FormFeedback invalid="true" >{error.help_error}</FormFeedback>
    </>
  }

  function addQuestion() {
    var error = false;
    if (!currentQuestion?.type) {
      setError((error) => {
        return { ...error, question_type_error: "Please select a question type" }
      })
      error = true
    } else {
      setError((error) => {
        return { ...error, question_type_error: "" }
      })

    }
    if (currentQuestion?.type === "SECTION") {
      if (!currentQuestion?.sectionName) {
        setError((error) => {
          return { ...error, section_name_error: "Please select a section name" }
        })
        error = true
      } else {
        setError((error) => {
          return { ...error, section_name_error: "" }
        })

      }
    }


    if (currentQuestion?.type !== "SECTION") {

      if (!currentQuestion?.dimension) {
        setError((error) => {
          return { ...error, dimension_error: "Please select a dimension" }
        })
        error = true;
      } else {
        setError((error) => {
          return { ...error, dimension_error: "" }
        })
      }
      if (!currentQuestion?.category) {
        setError((error) => {
          return { ...error, category_error: "Please select a category" }

        })
        error = true;
      } else {
        setError((error) => {
          return { ...error, category_error: "" }
        })

      }
      if (!currentQuestion?.competency) {
        setError((error) => {
          return { ...error, competency_error: "Please select a competnacy" }
        })

        error = true;
      } else {
        setError((error) => {
          return { ...error, competency_error: "" }
        })
      }
      if (!currentQuestion?.text) {
        setError((error) => {
          return { ...error, text_error: "Please enter question text" }
        })

        error = true;
      } else {
        setError((error) => {
          return { ...error, text_error: "" }
        })

      }

    }

    if (!currentQuestion?.text) {
      setError((error) => {
        return { ...error, text_section_error: "Please section title" }
      })
      error = true;
    } else {
      setError((error) => {
        return { ...error, text_section_error: "" }
      })

    }

    if (!error) {
      if (currentQuestion?.type === "SECTION") {
        onQuestionChanged({
          id: currentQuestion.id,
          text: currentQuestion.text,
          type: "SECTION",
          sectionName: currentQuestion.sectionName,
          help: currentQuestion.help
        })
      } else {
        onQuestionChanged(currentQuestion)
      }
    };
  }

  return <Modal isOpen={isOpen} toggle={onClose}>
    <ModalHeader toggle={onClose}>
      Add Question
    </ModalHeader>
    <ModalBody>
      <Form >
        <Label><b> Possible Variables: </b> </Label>
        <ul>
          {possibleVariables.map((variable, idx) => (
            <CopyToClipboard
              text={variable}
              options={{ format: "text/plain" }}
              onCopy={() => {
                setCopied(idx)
                window.setTimeout(
                  () => {
                    setCopied(-1)
                  },
                  1500
                );
              }}
            >
              <li className='pointer'>
                {variable}
                <span
                  className={`ml-3 font-weight-bold text-success ${copied === idx ? 'd-inline-block' : 'd-none'
                    }`}
                >
                  Copied
                </span>
              </li>
            </CopyToClipboard>
          )
          )}
        </ul>
        <b>
          Note:
          *|Variable|*,
          Kindly
          use
          variable
          as
          mentioned
        </b>
        <br />
        <Label>Type</Label>
        <Input
          name="type"
          type="select"
          defaultValue={currentQuestion?.type}
          valid={!!!error.question_type_error}
          invalid={!!error.question_type_error}
          onChange={e => {
            setCurrentQuestion({ ...currentQuestion, type: e.target.value })
            setError((error) => {
              return { ...error, question_type_error: "" }
            })

          }}
        >
          <option hidden >Type</option>
          {components?.find(component => {
            return component.id === "question_type";
          })?.types.map(type => {
            return <option key={type} value={type}>{type}</option>;
          })}
        </Input>

        <FormFeedback invalid={error.question_type_error} >
          {error.question_type_error}
        </FormFeedback>

        {currentQuestion?.type == "SECTION" ? addSectionComponent() : addQuestionComponent()}
        <Row className="mt-2">
          <Col className="text-right">
            <Button color="default" size="md" onClick={addQuestion}>
              Save
            </Button>
          </Col>
        </Row>
      </Form>
    </ModalBody>
  </Modal>

}

const mapStateToProps = state => {
  return {
    uid: state.firebase.auth.uid,
    components: state.firestore.ordered.components,
  };
};

export default compose(
  firestoreConnect([{ collection: "components" }]),
  connect(mapStateToProps)
)(AddEditQuestion);
