import Header from "components/Headers/Header";
import React, { Component } from "react";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { Link, Redirect } from "react-router-dom";
import RLDD from 'react-list-drag-and-drop/lib/RLDD';
// reactstrap components
import {
	Card,
	CardHeader,
	Container,
	Row,
	Col,
	Button,
	Form,
	Label,
	Input,
	CardBody,
	FormFeedback,
	InputGroup,
	FormGroup,
} from "reactstrap";
import { compose } from "redux";
import {
	addQuestion,
	deleteQuestion,
	editAssessments,
	editQuestion,
} from "actions/assessmentActions";
import AddEditQuestion from "./AddEditQuestion";

class editAssessment extends Component {
	state = {
		Question: false,
		redirect: false,
		questions: [],
		editQuestion: null,
		dimension_filter: "",
		type_filter: "",
		competency_filter: "",
		category_filter: "",
		type: "free text",
	};

	toggle = e => {
		e.preventDefault();
		this.setState({ Question: !this.state.Question });
	};

	editToggle = e => {
		e.preventDefault();
		this.setState({ editQuestion: "" });
	};

	addQuestion = (question, assessmentId) => {
		this.props.addQuestion(question, assessmentId);
	};

	questionFilters(assessment) {
		if (
			this.state.category_filter === "" &&
			this.state.dimension_filter === "" &&
			this.state.type_filter === ""
		) {
			return assessment.questions;
		} else {
			let arr = [
				{ state: "category_filter", com: "category" },
				{ state: "type_filter", com: "type" },
				{ state: "dimension_filter", com: "dimension" },
				{ state: "competency_filter", com: "competency" },
			];
			let pushable = [];
			arr.forEach(item => {
				if (this.state[item.state] !== "") {
					pushable.push(item);
				}
			});
			return assessment.questions.filter(item => {
				let flag = true;
				pushable.forEach(its => {
					if (this.state[its.state] !== item[its.com]) {
						flag = false;
					}
				});
				return flag;
			});
		}
	}

	deleteQuestion = (question, assessmentId) => {
		this.props.deleteQuestion(question, assessmentId);
	};

	editAssessment = (assessment, assessmentId) => {
		this.props.editAssessment(assessment, assessmentId);
	};

	editQuestion = (assessment,) => {
		this.props.editQuestion(assessment, assessment.id);
	};

	removeOldId(questions) {
		return questions.map((q) => {
			const question = {
				...q,
				id: q.oldId,
			}
			delete question.oldId
			return question
		})
	}


	render() {
		if (this.state.redirect) {
			return <Redirect to="/admin/assessments" />;
		}
		const { uid, components, assessments } = this.props;
		const assessmentId = this.props.match.params.id;
		var thisAssessment =
			assessments?.find(assessment => assessment.id === assessmentId);

		if (!thisAssessment) return <div />
		if (!uid) {
			return <Redirect to="/auth/login" />;
		} else {


			return (
				<>
					<Header />
					{/* Page content */}
					<Container className="mt--9" fluid>
						{/* forms */}
						<Row>
							<div className="col">
								<Card className=" shadow ">
									<CardHeader className=" border-0 ">
										<div style={{ display: "flex" }}>
											<Link to="/admin/assessments" className="text-default ">
												<i
													className="fas fa-long-arrow-alt-left "
													style={{ fontSize: "25px" }}
												/>
											</Link>
											<h3 className="ml-3 mb-0 ">
												<i className="ni ni-settings  " />
												&nbsp; Edit Assessment
											</h3>
										</div>{" "}
									</CardHeader>
									<CardBody className=" mb-0 ">
										<Form
											key={thisAssessment.id}
											onSubmit={e => {
												e.preventDefault();
												var error = false;
												if (!e.target.title.value) {
													this.setState({ title_error: "Please Add a Title" });
													error = true;
												} else {
													this.setState({
														title_error: "",
														title: e.target.title.value,
													});
												}
												if (!e.target.description.value) {
													this.setState({
														description_error: "Please Add a Description",
													});
													error = true;
												} else {
													this.setState({
														description_error: "",
														description: e.target.description.value,
													});
												}
												if (!e.target.price.value) {
													this.setState({
														price_error: "Please Add some Price",
													});
													error = true;
												} else {
													this.setState({
														price_error: "",
														price: e.target.price.value,
													});
												}
												if (e.target.type.value === "Type") {
													this.setState({ type_error: "Please Select a type" });
													error = true;
												} else {
													this.setState({
														type_error: "",
														type: e.target.type.value,
													});
												}
												if (!e.target.status.value) {
													this.setState({ status_error: "Please select status" });
													error = true;
												} else {
													this.setState({
														status_error: "",
														status: e.target.status.value,
													});
												}
												if (!e.target.version.value) {
													this.setState({
														version_error: "Please Add a Version",
													});
													error = true;
												} else {
													this.setState({
														version_error: "",
														version: e.target.version.value,
													});
												}

												if (!error) {
													const assessment = {
														title: e.target.title.value,
														description: e.target.description.value,
														price: e.target.price.value,
														type: e.target.type.value,
														version: e.target.version.value,
														currency: e.target.currency.value,
														status: e.target.status.value,

													};
													this.editAssessment(assessment, assessmentId);
													this.setState({
														redirect: true,
														title: "",
														title_error: "",
														description: "",
														description_error: "",
														price: "",
														price_error: "",
														type: "",
														type_error: "",
														version: "",
														version_error: "",
													});
												}
											}}
										>
											<Label> Title</Label>
											<Input
												name="title"
												defaultValue={thisAssessment && thisAssessment.title}
												valid={!this.state.title_error && this.state.title}
												invalid={this.state.title_error}
											/>
											<FormFeedback>{this.state.title_error}</FormFeedback>
											<Label> Description</Label>
											<Input
												name="description"
												defaultValue={
													thisAssessment && thisAssessment.description
												}
												type="textarea"
												valid={
													!!!this.state.description_error &&
													!!this.state.description
												}
												invalid={this.state.description_error}
											/>
											<FormFeedback>
												{this.state.description_error}
											</FormFeedback>
											<Label>Type</Label>
											<Input
												name="type"
												type="select"
												defaultValue={thisAssessment?.type}
												valid={!!!this.state.type_error && !!this.state.type}
												invalid={!!this.state.type_error}
											>
												<option hidden>Type</option>
												{components?.find(component => {
													return component.id === "assessment_type";
												})
													?.types.map(type => {
														return (
															<option
																key={type}
															>
																{type}
															</option>
														);
													})}
											</Input>
											<FormFeedback>{this.state.type_error}</FormFeedback>
											<Label>Status</Label>
											<Input
												name="status"
												type="select"
												valid={!this.state?.status_error && this.status?.status}
												invalid={this.state?.status_error}
												defaultValue={thisAssessment?.status}
											>
												<option value="active">Active</option>
												<option value="inactive">In Active</option>
											</Input>
											<FormFeedback>{this.state.status_error}</FormFeedback>

											<Label>Version</Label>
											<Input
												name="version"
												defaultValue={thisAssessment && thisAssessment.version}
												valid={!this.state.version_error && this.state.version}
												invalid={this.state.version_error}
											/>
											<FormFeedback>{this.state.version_error}</FormFeedback>
											<Label>Price</Label>
											<Input
												name="price"
												defaultValue={thisAssessment && thisAssessment.price}
												type="number"
												min={0}
												valid={!this.state.price_error && this.state.price}
												invalid={this.state.price_error}
											/>
											<FormFeedback>{this.state.price_error}</FormFeedback>

											<Label>Currency</Label>
											<Input
												name="currency"
												type="select"
												defaultValue={thisAssessment?.currency}
											>
												<option

													value="USD"
												>
													USD
												</option>
												<option

													value="EUR"
												>
													EUR
												</option>
											</Input>

											<Button
												className="mt-2"
												type="button"
												color="default"
												size="md"
												onClick={() => {
													this.setState({
														editQuestion: {}
													})
												}}
											>
												Add Question
											</Button>
											<Row>
												<Col className="text-right">
													<Button
														className="mt-2"
														type="submit"
														color="default"
														size="md"
													>
														Save Assessment
													</Button>
												</Col>
											</Row>
										</Form>

										<Form
											onSubmit={e => {
												e.preventDefault();
												e.target.dimension_select.value = "";
												e.target.type_select.value = "";
												e.target.competency_select.value = "";
												e.target.category_select.value = "";

												this.setState({
													dimension_filter: "",
													type_filter: "",
													competency_filter: "",
													category_filter: "",
												});
											}}
										>
											<Row className="mt-2">
												<Col md={3} xs={12}>
													<FormGroup>
														<InputGroup className="mb-3 ">
															<Input
																type="select"
																name="dimension_select"
																id="exampleSelect"
																onChange={e => {
																	this.setState({
																		dimension_filter: e.target.value,
																	});
																}}
															>
																<option value="">-- Dimensions--</option>
																{this.props?.components?.find((dim) => dim.id === "question_dimension")?.dimensions?.map(
																	item => {
																		return (
																			<option key={item} value={item}>{item}</option>
																		);
																	}
																)}
															</Input>
														</InputGroup>
													</FormGroup>
												</Col>
												<Col md={3} xs={12}>
													<FormGroup>
														<InputGroup className="mb-3 ">
															<Input
																type="select"
																name="type_select"
																id="exampleSelect"
																onChange={e => {
																	this.setState({
																		type_filter: e.target.value,
																	});
																}}
															>
																<option value="">-- Types--</option>
																{this.props.components?.find((dim) => dim.id === "question_type")?.types.map(item => {
																	return <option key={item} value={item}>{item}</option>;
																})}
															</Input>
														</InputGroup>
													</FormGroup>
												</Col>
												<Col md={3} xs={12}>
													<FormGroup>
														<InputGroup className="mb-3 ">
															<Input
																type="select"
																name="competency_select"
																id="exampleSelect"
																onChange={e => {
																	this.setState({
																		competency_filter: e.target.value,
																	});
																}}
															>
																<option value="">-- Competency--</option>
																{this.props.components &&
																	this.props.components?.find((dim) => dim.id === "question_competancy")?.competencies.map(
																		item => {
																			return (
																				<option key={item} value={item}>{item}</option>
																			);
																		}
																	)}
															</Input>
														</InputGroup>
													</FormGroup>
												</Col>
												<Col md={3} xs={12}>
													<FormGroup>
														<InputGroup className="mb-3 ">
															<Input
																type="select"
																name="category_select"
																id="exampleSelect"
																onChange={e => {
																	this.setState({
																		category_filter: e.target.value,
																	});
																}}
															>
																<option value="">-- Category--</option>
																{this.props.components &&
																	this.props.components?.find((dim) => dim.id === "question_category")?.categories.map(
																		item => {
																			return (
																				<option key={item} value={item}>{item}</option>
																			);
																		}
																	)}
															</Input>
														</InputGroup>
													</FormGroup>
												</Col>
												<Col md={2} xs={12}>
													<Button type="submit">Clear</Button>
												</Col>
											</Row>
										</Form>
										{/* thisAssessment.questions */}
										<RLDD
											items={JSON.parse(JSON.stringify(this.questionFilters(thisAssessment))).map((q, i) => {
												return {
													...q,
													oldId: q.id,
													id: i,
												}
											})}
											onChange={(items) => {
												const newAssessment = JSON.parse(JSON.stringify(thisAssessment))
												newAssessment.questions = this.removeOldId(items)
												this.editQuestion(newAssessment)
											}}
											itemRenderer={(question) => <Row key={question.id ?? question.text} style={{ backgroundColor: `${question?.type === "SECTION" ? "#ffe7e7" : question?.type === "SCALE" ? "#ffffe7" : "#e8fbe8"}` }} className="mt-4">
												<Col xs={{ size: 8 }}>
													<h3 className=" mb-0 ">
														{question?.type === "SECTION" ? "Section" : "Question"}: {question.text}
													</h3>
													{question?.type !== "SECTION" ? (
														<Row className="mt-3">
															<Col md={6}>
																<p>Demension : {question.dimension}</p>
															</Col>
															<Col md={6}>
																<p>Category : {question.category}</p>
															</Col>
															<Col md={6}>
																<p>Competency : {question.competency}</p>
															</Col>
															<Col md={6}>
																<p>Type : {question.type}</p>
															</Col>
														</Row>
													) :
														<Col md={6}>
															{question?.help && <p> Description : {question.help}</p>}
														</Col>

													}

												</Col>
												<Col xs={{ size: 4 }} className="text-right">
													<Button
														className="mt-2"
														type="button"
														color="default"
														onClick={e => {
															e.preventDefault();
															const editingQuestion = JSON.parse(JSON.stringify(question))
															editingQuestion.id = question.oldId
															delete editingQuestion.oldId
															this.setState({
																editQuestion: editingQuestion
															});
														}}
														size="md"
													>
														Edit
													</Button>
													<Button
														className="mt-2"
														type="button"
														color="default"
														onClick={e => {
															e.preventDefault();
															question.id = question.oldId
															delete question.oldId
															this.deleteQuestion(
																question,
																assessmentId
															);
														}}
														size="md"
													>
														Delete
													</Button>
												</Col>
												<div style={{ width: "100%", height: "1px", backgroundColor: "#182A4D" }} > </div>
											</Row>}
										/>
										<AddEditQuestion
											question={this.state.editQuestion}
											isOpen={!!this.state.editQuestion}
											onClose={() => {
												this.setState({ editQuestion: null })
											}}
											onQuestionChanged={(question) => {
												const questionIds = thisAssessment.questions.map((q) => q.id)
												const newAssessment = JSON.parse(JSON.stringify(thisAssessment))
												if (questionIds.includes(question.id)) {
													// Edit
													const questionIndex = questionIds.indexOf(question.id)
													newAssessment.questions[questionIndex] = question
													this.props.editQuestion(newAssessment, newAssessment.id)

												} else {
													this.props.addQuestion(question, newAssessment.id)
												}
												this.setState({
													editQuestion: null
												})
											}}

										/>

									</CardBody>
								</Card>
							</div>
						</Row>
					</Container>
				</>
			);
		}
	}
}
const mapStateToProps = state => {
	return {
		uid: state.firebase.auth.uid,
		components: state.firestore.ordered.components,
		assessments: state.firestore.ordered.assessments,
	};
};
const mapDispatchToProps = dispatch => {
	return {
		addQuestion: (question, assessmentId) =>
			dispatch(addQuestion(question, assessmentId)),
		deleteQuestion: (question, assessmentId) =>
			dispatch(deleteQuestion(question, assessmentId)),
		editAssessment: (assessment, assessmentId) =>
			dispatch(editAssessments(assessment, assessmentId)),
		editQuestion: (assessment, assessmentId) =>
			dispatch(editQuestion(assessment, assessmentId)),

		// deleteCompany: (company) =>
		//   dispatch(deleteCompany(company)),
		// editCompany: (company, newCompany) =>
		//   dispatch(editCompany(company, newCompany)),
	};
};

export default compose(
	firestoreConnect([
		{ collection: "components" },
		{ collection: "assessments" },
	]),
	connect(mapStateToProps, mapDispatchToProps)
)(editAssessment);
