import Header from "components/Headers/Header";
import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
// reactstrap components
import {
	Card,
	CardHeader,
	Pagination,
	PaginationItem,
	PaginationLink,
	Table,
	Container,
	Row,
	Col,
	Button,
	Form,
	Input,
	CardBody,
	InputGroup,
	InputGroupAddon,
	FormFeedback,
} from "reactstrap";
import { compose } from "redux";
import { firestoreConnect } from "react-redux-firebase";
import { connect } from "react-redux";
import {
	addClientIndustry,
	editClientIndustry,
	deleteClientIndustry,
	addQuestionDimension,
	editQuestionDimension,
	deleteQuestionDimension,
	addQuestionCategory,
	editQuestionCategory,
	deleteQuestionCategory,
	addQuestionType,
	editQuestionType,
	deleteQuestionType,
	addAssessmentType,
	editAssessmentType,
	deleteAssessmentType,
	addQuestionCompetency,
	editQuestionCompetency,
	deleteQuestionCompetency,
} from "actions/componentsActions";
import { addRaterRole, deleteRaterRole, editRaterRole, addRaterRelationship, deleteRaterRelationship, editRaterRelationship } from "actions/componentsActions";


class AssessmentComponents extends Component {
	state = {
		editAT: true,
		editQT: true,
		editQC: true,
		editQCM: true,
		editQD: true,
		edit: true,
		editRole: true,
		editRelationship: true,
		type: "",
		orderType: "Ascending",
		QuestionType: "Ascending",
		QuestionCategory: "Ascending",
		QuestionCompetency: "Ascending",
		QuestionDimension: "Ascending",
		ClientIndustry: "Ascending",
		RaterRole: "Ascending",
		RaterRelationship: "Ascending",

		changeType: "",
		category: "",
		changeCategory: "",
		competency: "",
		changeCompetency: "",
		dimension: "",
		changeDimension: "",
		industry: "",
		changeIndustry: "",
		changeRole: "",
		changeRelationship: "",
		searchAT: "",
		searchQT: "",
		searchQC: "",
		searchQCM: "",
		searchQD: "",
		search: "",
		paginationAT: 1,
		paginationQT: 1,
		paginationQC: 1,
		paginationQCM: 1,
		paginationQD: 1,
		pagination: 1,
		paginationRole: 1,
	};
	addAssessmentType = e => {
		e.preventDefault();
		var error = false;
		let obj =
			this.props.components &&
			this.props.components[0].types.find(
				item => item === e.target.assessment_type.value
			);
		if (!e.target.assessment_type.value) {
			this.setState({ assessment_type_error: "Please add Text" });
			error = true;
		} else if (obj !== null) {
			this.setState({
				assessment_type_error: "Assessment type already exists",
			});
			error = true;
		} else {
			this.setState({
				assessment_type_error: "",
				assessment_type: e.target.assessment_type.value,
			});
		}
		if (!error) {
			this.props.addAssessmentType(e.target.assessment_type.value);
			e.target.assessment_type.value = "";
		}
	};
	deleteAssessmentType = type => {
		let obj = this.props.assessments.find(item => item.type === type);
		if (obj === null) {
			this.props.deleteAssessmentType(type);
		} else {
			// "+obj.title+"
			alert("This item is currently used in  assessment so we cant remove it");
		}
	};
	addToEditAT = type => {
		this.setState({ type: type, editAT: false, changeType: type });
	};

	assQustionFilters = (arg, mystate) => {
		let resp = [];
		let comp_copy = JSON.stringify(this.props.components);
		let parsed_copy = JSON.parse(comp_copy);
		let filtered_arrs = parsed_copy.filter(comp => comp.id === arg);
		if (filtered_arrs.length > 0) {
			// .QuestionType
			if (this.state[mystate] === "Ascending") {
				if (filtered_arrs[0].types !== null) {
					filtered_arrs[0].types = filtered_arrs[0].types.sort();
					return filtered_arrs;
				} else {
					return [];
				}
			} else if (this.state[mystate] === "Descending") {
				filtered_arrs[0].types = filtered_arrs[0].types.sort().reverse();
				return filtered_arrs;
			}
		} else {
			return [];
		}
	};

	assQuestionCategoryFilter = arg => {
		let resp = [];
		let comp_copy = JSON.stringify(this.props.components);
		let parsed_copy = JSON.parse(comp_copy);
		let filtered_arrs = parsed_copy.filter(comp => comp.id === arg);
		if (filtered_arrs.length > 0) {
			if (this.state.QuestionCategory === "Ascending") {
				if (filtered_arrs[0].categories !== null) {
					filtered_arrs[0].categories = filtered_arrs[0].categories.sort();
					return filtered_arrs;
				} else {
					return [];
				}
			} else if (this.state.QuestionCategory === "Descending") {
				filtered_arrs[0].categories = filtered_arrs[0].categories
					.sort()
					.reverse();
				return filtered_arrs;
			}
		} else {
			return [];
		}
	};

	assTypeFilters = arg => {
		let resp = [];
		let comp_copy = JSON.stringify(this.props.components);
		let parsed_copy = JSON.parse(comp_copy);
		let filtered_arrs = parsed_copy.filter(comp => comp.id === arg);
		if (filtered_arrs.length > 0) {
			if (this.state.orderType === "Ascending") {
				if (filtered_arrs[0].types !== null) {
					filtered_arrs[0].types = filtered_arrs[0].types.sort();
					return filtered_arrs;
				} else {
					return [];
				}
			} else if (this.state.orderType === "Descending") {
				filtered_arrs[0].types = filtered_arrs[0].types.sort().reverse();
				return filtered_arrs;
			}
		} else {
			return [];
		}
	};

	questionCompetencyFilter = arg => {
		let resp = [];
		let comp_copy = JSON.stringify(this.props.components);
		let parsed_copy = JSON.parse(comp_copy);
		let filtered_arrs = parsed_copy.filter(comp => comp.id === arg);
		if (filtered_arrs.length > 0) {
			if (this.state.QuestionCompetency === "Ascending") {
				if (filtered_arrs[0].competencies !== null) {
					filtered_arrs[0].competencies = filtered_arrs[0].competencies.sort();
					return filtered_arrs;
				} else {
					return [];
				}
			} else if (this.state.QuestionCompetency === "Descending") {
				filtered_arrs[0].competencies = filtered_arrs[0].competencies
					.sort()
					.reverse();
				return filtered_arrs;
			}
		} else {
			return [];
		}
	};

	questionDimensionFilter = arg => {
		let resp = [];
		let comp_copy = JSON.stringify(this.props.components);
		let parsed_copy = JSON.parse(comp_copy);
		let filtered_arrs = parsed_copy.filter(comp => comp.id === arg);
		if (filtered_arrs.length > 0) {
			if (this.state.QuestionDimension === "Ascending") {
				if (filtered_arrs[0].dimensions !== null) {
					filtered_arrs[0].dimensions = filtered_arrs[0].dimensions.sort();
					return filtered_arrs;
				} else {
					return [];
				}
			} else if (this.state.QuestionDimension === "Descending") {
				filtered_arrs[0].dimensions = filtered_arrs[0].dimensions
					.sort()
					.reverse();
				return filtered_arrs;
			}
		} else {
			return [];
		}
	};


	clientIndustryFilter = arg => {
		let resp = [];
		let comp_copy = JSON.stringify(this.props.components);
		let parsed_copy = JSON.parse(comp_copy);
		let filtered_arrs = parsed_copy?.filter(comp => comp.id === arg);
		if (filtered_arrs.length > 0) {
			if (this.state.ClientIndustry === "Ascending") {
				if (filtered_arrs[0].industries !== null) {
					filtered_arrs[0].industries = filtered_arrs[0].industries.sort();
					return filtered_arrs;
				} else {
					return [];
				}
			} else if (this.state.ClientIndustry === "Descending") {
				filtered_arrs[0].industries = filtered_arrs[0].industries
					.sort()
					.reverse();
				return filtered_arrs;
			}
		} else {
			return [];
		}
	};
	raterRolesFilter = (arg) => {
		let resp = [];
		let comp_copy = JSON.stringify(this.props.components);
		let parsed_copy = JSON.parse(comp_copy);
		let filtered_arrs = parsed_copy.filter(comp => comp.id === arg);
		if (filtered_arrs?.length > 0) {
			if (this.state.RaterRole === "Ascending") {
				if (filtered_arrs[0].role !== null) {
					filtered_arrs[0].role = filtered_arrs[0].role.sort();
					return filtered_arrs;
				} else {
					return [];
				}
			} else if (this.state.RaterRole === "Descending") {
				filtered_arrs[0].role = filtered_arrs[0].role.sort().reverse();
				return filtered_arrs;
			}
		} else {
			return [];
		}
	};

	raterRelationshipsFilter = (arg) => {
		let resp = [];
		let comp_copy = JSON.stringify(this.props.components);
		let parsed_copy = JSON.parse(comp_copy);
		let filtered_arrs = parsed_copy?.filter(comp => comp.id === arg);
		if (filtered_arrs?.length > 0) {
			if (this.state.RaterRelationship === "Ascending") {
				if (filtered_arrs[0].relationship !== null) {
					filtered_arrs[0].relationship = filtered_arrs[0].relationship.sort();
					return filtered_arrs;
				} else {
					return [];
				}
			} else if (this.state.RaterRelationship === "Descending") {
				filtered_arrs[0].relationship = filtered_arrs[0].relationship.sort().reverse();
				return filtered_arrs;
			}
		} else {
			return [];
		}
	};



	editAssessmentType = e => {
		e.preventDefault();

		this.props.editAssessmentType(
			this.state.changeType,
			e.target.assessment_type.value
		);
		this.setState({ editAT: true, type: "", changeType: "" });
	};
	searchAT = e => {
		e.preventDefault();
		this.setState({ searchAT: e.target.value });
	};
	addQuestionType = e => {
		e.preventDefault();
		var error = false;
		let obj =
			this.props.components &&
			this.props.components[6].types.find(
				item => item === e.target.question_type.value
			);

		if (!e.target.question_type.value) {
			this.setState({ question_type_error: "Please add Text" });
			error = true;
		} else if (obj !== null) {
			this.setState({ question_type_error: "Question type already exists" });
			error = true;
		} else {
			this.setState({
				question_type_error: "",
				question_type: e.target.question_type.value,
			});
		}
		if (!error) {
			this.props.addQuestionType(e.target.question_type.value);
			e.target.question_type.value = "";
		}
	};
	deleteQuestionType = type => {
		let obj = this.props.assessments.filter(item =>
			item.questions.find(it => it.type === type)
		);
		if (obj.length === 0) {
			this.props.deleteQuestionType(type);
		} else {
			alert("This item is currently used  so we cant remove it");
		}
	};
	deleteRaterRole = role => {
		this.props.deleteRaterRole(role);
	};
	addToEditQT = type => {
		this.setState({ type: type, editQT: false, changeType: type });
	};
	editQuestionType = e => {
		e.preventDefault();

		this.props.editQuestionType(
			this.state.changeType,
			e.target.question_type.value
		);
		this.setState({ editQT: true, type: "", changeType: "" });
	};
	searchQT = e => {
		e.preventDefault();
		this.setState({ searchQT: e.target.value });
	};
	addQuestionCategory = e => {
		e.preventDefault();
		var error = false;
		let obj =
			this.props.components &&
			this.props.components[3].categories.find(
				item => item === e.target.question_category.value
			);
		if (!e.target.question_category.value) {
			this.setState({ question_category_error: "Please add Text" });
			error = true;
		} else if (obj !== null) {
			this.setState({
				question_category_error: "Question category already exists",
			});
			error = true;
		} else {
			this.setState({
				question_category_error: "",
				question_category: e.target.question_category.value,
			});
		}
		if (!error) {
			this.props.addQuestionCategory(e.target.question_category.value);
			e.target.question_category.value = "";
		}
	};
	deleteQuestionCategory = category => {
		let obj = this.props.assessments.filter(item =>
			item.questions.find(it => it.category === category)
		);
		if (obj.length === 0) {
			this.props.deleteQuestionCategory(category);
		} else {
			alert("This item is currently used so we cant remove it");
		}
	};
	addToEditQC = category => {
		this.setState({
			category: category,
			editQC: false,
			changeCategory: category,
		});
	};
	editQuestionCategory = e => {
		e.preventDefault();

		this.props.editQuestionCategory(
			this.state.changeCategory,
			e.target.question_category.value
		);
		this.setState({ editQC: true, category: "", changeCategory: "" });
	};
	searchQC = e => {
		e.preventDefault();
		this.setState({ searchQC: e.target.value });
	};
	addQuestionCompetency = e => {
		e.preventDefault();
		var error = false;
		let obj =
			this.props.components &&
			this.props.components[4].competencies.find(
				item => item === e.target.question_competency.value
			);

		if (!e.target.question_competency.value) {
			this.setState({ question_competency_error: "Please add Text" });
			error = true;
		} else if (obj !== null) {
			this.setState({
				question_competency_error: "Question Competency already exists",
			});
			error = true;
		} else {
			this.setState({
				question_competency_error: "",
				question_competency: e.target.question_competency.value,
			});
		}
		if (!error) {
			this.props.addQuestionCompetency(e.target.question_competency.value);
			e.target.question_competency.value = "";
		}
	};
	deleteQuestionCompetency = competency => {
		let obj = this.props.assessments.filter(item =>
			item.questions.find(it => it.competency === competency)
		);
		if (obj.length === 0) {
			this.props.deleteQuestionCompetency(competency);
		} else {
			alert("This item is currently used so we cant remove it");
		}
	};
	addToEditQCM = competency => {
		this.setState({
			competency: competency,
			editQCM: false,
			changeCompetency: competency,
		});
	};
	editQuestionCompetency = e => {
		e.preventDefault();

		this.props.editQuestionCompetency(
			this.state.changeCompetency,
			e.target.question_competency.value
		);
		this.setState({ editQCM: true, competency: "", changeCompetency: "" });
	};
	searchQCM = e => {
		e.preventDefault();
		this.setState({ searchQCM: e.target.value });
	};
	addQuestionDimension = e => {
		e.preventDefault();
		var error = false;
		let obj =
			this.props.components &&
			this.props.components[5].dimensions.find(
				item => item === e.target.question_dimension.value
			);

		if (!e.target.question_dimension.value) {
			this.setState({ question_dimension_error: "Please add Text" });
			error = true;
		} else if (obj !== null) {
			this.setState({
				question_dimension_error: "Question Dimension already exists",
			});
			error = true;
		} else {
			this.setState({
				question_dimension_error: "",
				question_dimension: e.target.question_dimension.value,
			});
		}
		if (!error) {
			this.props.addQuestionDimension(e.target.question_dimension.value);
			e.target.question_dimension.value = "";
		}
	};
	deleteQuestionDimension = dimension => {
		let obj = this.props.assessments.filter(item =>
			item.questions.find(it => it.dimension === dimension)
		);
		if (obj.length === 0) {
			this.props.deleteQuestionDimension(dimension);
		} else {
			alert("This item is currently used so we cant remove it");
		}
	};
	addToEditQD = dimension => {
		this.setState({
			dimension: dimension,
			editQD: false,
			changeDimension: dimension,
		});
	};
	editQuestionDimension = e => {
		e.preventDefault();

		this.props.editQuestionDimension(
			this.state.changeDimension,
			e.target.question_dimension.value
		);
		this.setState({ editQD: true, dimension: "", changeDimension: "" });
	};
	searchQD = e => {
		e.preventDefault();
		this.setState({ searchQD: e.target.value });
	};
	addClientIndustry = e => {
		e.preventDefault();
		var error = false;
		let obj =
			this.props.components &&
			this.props.components[1].industries.find(
				item => item === e.target.client_industry.value
			);

		if (!e.target.client_industry.value) {
			this.setState({ client_industry_error: "Please add Text" });
			error = true;
		} else if (obj !== null) {
			this.setState({
				client_industry_error: "Client Industry already exists",
			});
			error = true;
		} else {
			this.setState({
				client_industry_error: "",
				client_industry: e.target.client_industry.value,
			});
		}
		if (!error) {
			this.props.addClientIndustry(e.target.client_industry.value);
			e.target.client_industry.value = "";
		}
	};
	addRaterRole = e => {
		e.preventDefault();
		var error = false;
		let obj = this.props.components?.find((com) => {
			return com.id === "rater_roles";
		}).role.includes(e.target.rater_role.value);
		if (!e.target.rater_role.value) {
			this.setState({ rater_role_error: "Please add Text" });
			error = true;
		} else {
			this.setState({
				rater_role_error: "",
				role: e.target.rater_role.value,
			});
			error = false
		}
		if (obj) {
			this.setState({
				rater_role_error: "Rater Roles already exists",
			});
			error = true;
		} else {
			this.setState({
				rater_role_error: "",
				role: e.target.rater_role.value,
			});
			error = false
		}
		if (!error) {
			this.props.addRaterRole(e.target.rater_role.value);
			e.target.rater_role.value = "";
		}
	};

	addRaterRelationship = e => {
		e.preventDefault();
		var error = false;
		let obj = this.props.components?.find((com) => {
			return com.id === "rater_relationships";
		}).relationship?.includes(e.target.rater_relationship.value);
		console.log("obj", obj)
		console.log("value", e.target.rater_relationship.value)
		if (!e.target.rater_relationship.value) {
			this.setState({ rater_relationship_error: "Please add Text" });
			error = true;
		} else {
			this.setState({
				rater_relationship_error: "",
				relationship: e.target.rater_relationship.value,
			});
			error = false
		}
		if (obj) {
			this.setState({
				rater_relationship_error: "Rater relationship already exists",
			});
			error = true;
		} else {
			this.setState({
				rater_relationship_error: "",
				relationship: e.target.rater_relationship.value,
			});
			error = false
		}
		if (!error) {
			this.props.addRaterRelationship(e.target.rater_relationship.value);
			e.target.rater_relationship.value = "";
		}
	};
	deleteClientIndustry = industry => {
		let obj = this.props.clients.find(item => item.industry === industry);

		if (obj === null) {
			this.props.deleteClientIndustry(industry);
		} else {
			alert(
				"This item is currently used in  client industries so we cant remove it"
			);
		}
	};
	addToEdit = industry => {
		this.setState({
			industry: industry,
			edit: false,
			changeIndutry: industry,
		});
	};
	addToEditRole = role => {
		this.setState({
			role: role,
			editRole: false,
			changeRole: role,
		});
	};

	addToEditRelationship = relationship => {
		this.setState({
			relationship: relationship,
			editRelationship: false,
			changeRelationship: relationship,
		});
	}

	editClientIndustry = e => {
		e.preventDefault();

		this.props.editClientIndustry(
			this.state.changeIndutry,
			e.target.client_industry.value
		);
		this.setState({ edit: true, industry: "", changeIndutry: "" });
	};
	editRaterRole = e => {
		e.preventDefault();

		this.props.editRaterRole(this.state.changeRole, e.target.rater_role.value);
		this.setState({ editRole: true, role: "", changeRole: "" });
	};

	editRaterRelationship = e => {
		e.preventDefault();
		this.props.editRaterRelationship(this.state.changeRelationship, e.target.rater_relationship.value);
		this.setState({ editRelationship: true, relationship: "", changeRelationship: "" });
	}
	search = e => {
		e.preventDefault();
		this.setState({ search: e.target.value });
	};
	searchRole = e => {
		e.preventDefault();
		this.setState({ searchRole: e.target.value });
	};

	searchRelationship = e => {
		e.preventDefault();
		this.setState({ searchRelationship: e.target.value });
	}


	render() {
		let { uid, components } = this.props;

		if (!uid) {
			return <Redirect to="/auth/login" />;
		} else {
			return (
				<>
					<Header />
					{/* Page content */}
					<Container className="mt--9" fluid>
						{/* forms */}
						<Row>
							<Col>
								<Card className=" shadow ">
									<CardHeader className=" border-0 ">
										<h3 className=" mb-0 ">Assessment Components</h3>
									</CardHeader>
									<CardBody>
										<Row>
											{/* ASSESSMENT TYPE */}
											<Col xs={{ size: 12 }} lg={{ size: 6 }} className="mb-4">
												<Card className="shadow border-dark ">
													<CardBody className=" ">
														<div style={{ display: "flex" }}>
															<h3 className=" mb-0 ">Assessment Type</h3>
															<Link
																className="ml-2"
																to="/admin/assessmentcomponents/assessmenttype"
															>
																<Button color="white" size="sm">
																	<i className="fas fa-eye" />
																</Button>
															</Link>
														</div>
														<p className="text-info">
															Used to determine Assessment Type
														</p>

														<Form>
															<InputGroup className="mb-3 ">
																<Input
																	type="select"
																	name="select"
																	id="exampleSelect"
																	onChange={e => {
																		this.setState({
																			orderType: e.target.value,
																		});
																	}}
																>
																	<option value="Ascending">Ascending</option>
																	<option value="Descending">Descending</option>
																</Input>
															</InputGroup>
														</Form>
														<Form>
															<InputGroup className="mb-3 ">
																<Input
																	name="searchAT"
																	className=" "
																	onChange={this.searchAT}
																	placeholder="Search"
																/>
																<InputGroupAddon addonType="append">
																	<Button>
																		<i className="fas fa-search  " />
																	</Button>
																</InputGroupAddon>
															</InputGroup>
														</Form>
														<Form
															hidden={this.state.editAT}
															onSubmit={this.editAssessmentType}
															className=""
														>
															<InputGroup className=" ">
																<Input
																	name="assessment_type"
																	className=" "
																	value={this.state.type}
																	onChange={e => {
																		e.preventDefault();
																		this.setState({ type: e.target.value });
																	}}
																/>
																<InputGroupAddon addonType="append">
																	<Button>EDIT</Button>
																</InputGroupAddon>
															</InputGroup>
														</Form>

														<Form
															hidden={!this.state.editAT}
															onSubmit={this.addAssessmentType}
															className=""
														>
															<InputGroup className=" ">
																<Input
																	name="assessment_type"
																	className=" "
																	placeholder="Type"
																	valid={
																		!this.state.assessment_type_error &&
																		this.state.assessment_type
																	}
																	invalid={this.state.assessment_type_error}
																/>

																<InputGroupAddon addonType="append">
																	<Button>ADD</Button>
																</InputGroupAddon>
																<FormFeedback>
																	{this.state.assessment_type_error}
																</FormFeedback>
															</InputGroup>
														</Form>
														<Table
															className="align-items-center  table-flush mt-4"
															responsive
														>
															<thead className="thread-light">
																<tr>
																	<th scope="col"> Id</th>
																	<th scope="col">Type</th>
																	<th scope="col">Actions</th>
																</tr>
															</thead>
															<tbody>
																{components &&
																	this.assTypeFilters("assessment_type")
																		// .filter(
																		//   (comp) => comp.id === "assessment_type"
																		// )
																		.map(comp => {
																			return comp.types
																				.filter(type => {
																					if (this.state.searchAT) {
																						return type
																							.toLowerCase()
																							.includes(
																								this.state.searchAT.toLowerCase()
																							);
																					} else {
																						return true;
																					}
																				})
																				.splice(
																					5 * (this.state.paginationAT - 1),
																					5
																				)

																				.map((type, i) => {
																					return (
																						<tr
																							hidden={
																								this.state.changeType === type
																							}
																						>
																							<th scope="row">
																								<span className="mb-0 text-sm ">
																									{i + 1}
																								</span>
																							</th>
																							<td className=""> {type}</td>
																							<td>
																								<Button
																									type="button"
																									onClick={e => {
																										e.preventDefault();
																										this.addToEditAT(type);
																									}}
																									size="sm"
																									color="white"
																								>
																									<i className="ni ni-settings  " />
																								</Button>
																								<Button
																									type="button"
																									onClick={e => {
																										e.preventDefault();
																										this.deleteAssessmentType(
																											type
																										);
																									}}
																									size="sm"
																									color="white"
																								>
																									<i className="fas fa-trash  " />
																								</Button>
																							</td>
																						</tr>
																					);
																				});
																		})}
															</tbody>
														</Table>
														<Row className="mt-2">
															<Col md={4} xs={12}>
																<Pagination aria-label="Page navigation example">
																	<PaginationItem>
																		<PaginationLink
																			className=" "
																			previous
																			disabled={this.state.paginationAT === 1}
																			onClick={e => {
																				e.preventDefault();
																				this.setState({
																					paginationAT:
																						this.state.paginationAT - 1,
																				});
																			}}
																		/>
																	</PaginationItem>
																	<PaginationItem>
																		<PaginationLink className=" " disabled>
																			{this.state.paginationAT}
																		</PaginationLink>
																	</PaginationItem>
																	<PaginationItem>
																		<PaginationLink
																			className=" "
																			next
																			disabled={
																				components &&
																				components
																					.filter(
																						comp =>
																							comp.id === "assessment_type"
																					)[0]
																					?.types.filter(type => {
																						if (this.state.search) {
																							return type
																								.toLowerCase()
																								.includes(
																									this.state.search.toLowerCase()
																								);
																						} else {
																							return true;
																						}
																					}).length /
																				5 <=
																				this.state.paginationAT
																			}
																			onClick={e => {
																				e.preventDefault();
																				this.setState({
																					paginationAT:
																						this.state.paginationAT + 1,
																				});
																			}}
																		/>
																	</PaginationItem>
																</Pagination>
															</Col>
															<Col md={8} xs={12} className="text-left">
																<sub>
																	Page {this.state.paginationAT} of{" "}
																	<span>
																		{components &&
																			Math.ceil(
																				Math.floor(
																					components.filter(
																						comp =>
																							comp.id === "assessment_type"
																					)[0]?.types.length
																				) / 5
																			)}
																	</span>
																</sub>
															</Col>
														</Row>
													</CardBody>
												</Card>
											</Col>
											{/* QUESTION TYPE */}
											<Col xs={{ size: 12 }} lg={{ size: 6 }} className="mb-4">
												<Card className="shadow border-dark">
													<CardBody className=" ">
														<div style={{ display: "flex" }}>
															<h3 className=" mb-0 ">Question Type</h3>
															<Link
																className="ml-2"
																to="/admin/assessmentcomponents/questiontype"
															>
																<Button color="white" size="sm">
																	<i className="fas fa-eye" />
																</Button>
															</Link>
														</div>



														<Table
															className="align-items-center  table-flush mt-4"
															responsive
														>
															<thead className="thread-light">
																<tr>
																	<th scope="col"> Id</th>
																	<th scope="col">Type</th>
																</tr>
															</thead>
															<tbody>
																{components &&
																	this.assQustionFilters(
																		"question_type",
																		"QuestionType"
																	)
																		// .filter(
																		//   (comp) => comp.id === "question_type"
																		// )
																		.map(comp => {
																			return comp.types
																				.filter(type => {
																					if (this.state.searchQT) {
																						return type
																							.toLowerCase()
																							.includes(
																								this.state.searchQT.toLowerCase()
																							);
																					} else {
																						return true;
																					}
																				})
																				.splice(
																					5 * (this.state.paginationQT - 1),
																					5
																				)

																				.map((type, i) => {
																					return (
																						<tr
																							hidden={
																								this.state.changeType === type
																							}
																						>
																							<th scope="row">
																								<span className="mb-0 text-sm ">
																									{i + 1}
																								</span>
																							</th>
																							<td className=""> {type}</td>

																						</tr>
																					);
																				});
																		})}
															</tbody>
														</Table>
														<Row className="mt-2">
															<Col md={4} cols={12}>
																<Pagination aria-label="Page navigation example">
																	<PaginationItem>
																		<PaginationLink
																			className=" "
																			previous
																			disabled={this.state.paginationQT === 1}
																			onClick={e => {
																				e.preventDefault();
																				this.setState({
																					paginationQT:
																						this.state.paginationQT - 1,
																				});
																			}}
																		/>
																	</PaginationItem>
																	<PaginationItem>
																		<PaginationLink className=" " disabled>
																			{this.state.paginationQT}
																		</PaginationLink>
																	</PaginationItem>
																	<PaginationItem>
																		<PaginationLink
																			className=" "
																			next
																			disabled={
																				components &&
																				components
																					.filter(
																						comp => comp.id === "question_type"
																					)[0]
																					?.types.filter(type => {
																						if (this.state.search) {
																							return type
																								.toLowerCase()
																								.includes(
																									this.state.search.toLowerCase()
																								);
																						} else {
																							return true;
																						}
																					}).length /
																				5 <=
																				this.state.paginationQT
																			}
																			onClick={e => {
																				e.preventDefault();
																				this.setState({
																					paginationQT:
																						this.state.paginationQT + 1,
																				});
																			}}
																		/>
																	</PaginationItem>
																</Pagination>
															</Col>
															<Col md={8} xs={12} className="text-left">
																<sub>
																	Page {this.state.paginationAT} of{" "}
																	<span>
																		{components &&
																			Math.ceil(
																				Math.floor(
																					components.filter(
																						comp => comp.id === "question_type"
																					)[0]?.types.length
																				) / 5
																			)}
																	</span>
																</sub>
															</Col>
														</Row>
													</CardBody>
												</Card>
											</Col>
											{/* QUESTION CATEGORY */}
											<Col xs={{ size: 12 }} lg={{ size: 6 }} className="mb-4">
												<Card className="shadow border-dark">
													<CardBody className=" ">
														<div style={{ display: "flex" }}>
															<h3 className=" mb-0 ">Question Category</h3>
															<Link
																className="ml-2"
																to="/admin/assessmentcomponents/questioncategory"
															>
																<Button color="white" size="sm">
																	<i className="fas fa-eye" />
																</Button>
															</Link>
														</div>
														<p className="text-info">
															Used to determine Question Category
														</p>
														<Form>
															<InputGroup className="mb-3 ">
																<Input
																	type="select"
																	name="select"
																	id="exampleSelect"
																	onChange={e => {
																		this.setState({
																			QuestionCategory: e.target.value,
																		});
																	}}
																>
																	<option value="Ascending">Ascending</option>
																	<option value="Descending">Descending</option>
																</Input>
															</InputGroup>
														</Form>
														<Form>
															<InputGroup className="mb-3 ">
																<Input
																	name="searchQC"
																	className=" "
																	onChange={this.searchQC}
																	placeholder="Search"
																/>
																<InputGroupAddon addonType="append">
																	<Button>
																		<i className="fas fa-search  " />
																	</Button>
																</InputGroupAddon>
															</InputGroup>
														</Form>
														<Form
															hidden={this.state.editQC}
															onSubmit={this.editQuestionCategory}
															className=""
														>
															<InputGroup className=" ">
																<Input
																	name="question_category"
																	className=" "
																	value={this.state.category}
																	onChange={e => {
																		e.preventDefault();
																		this.setState({ category: e.target.value });
																	}}
																/>
																<InputGroupAddon addonType="append">
																	<Button>EDIT</Button>
																</InputGroupAddon>
															</InputGroup>
														</Form>
														<Form
															hidden={!this.state.editQC}
															onSubmit={this.addQuestionCategory}
															className=""
														>
															<InputGroup className=" ">
																<Input
																	name="question_category"
																	className=" "
																	placeholder="Category"
																	valid={
																		!this.state.question_category_error &&
																		this.state.question_category
																	}
																	invalid={this.state.question_category_error}
																/>

																<InputGroupAddon addonType="append">
																	<Button>ADD</Button>
																</InputGroupAddon>
																<FormFeedback>
																	{this.state.question_category_error}
																</FormFeedback>
															</InputGroup>
														</Form>
														<Table
															className="align-items-center  table-flush mt-4"
															responsive
														>
															<thead className="thread-light">
																<tr>
																	<th scope="col"> Id</th>
																	<th scope="col">Category</th>
																	<th scope="col">Actions</th>
																</tr>
															</thead>
															<tbody>
																{components &&
																	this.assQuestionCategoryFilter(
																		"question_category"
																	)

																		// components
																		// .filter(
																		//   (comp) => comp.id === "question_category"
																		// )
																		.map(comp => {
																			return comp.categories
																				.filter(category => {
																					if (this.state.searchQC) {
																						return category
																							.toLowerCase()
																							.includes(
																								this.state.searchQC.toLowerCase()
																							);
																					} else {
																						return true;
																					}
																				})
																				.splice(
																					5 * (this.state.paginationQC - 1),
																					5
																				)

																				.map((category, i) => {
																					return (
																						<tr
																							hidden={
																								this.state.changeCategory ===
																								category
																							}
																						>
																							<th scope="row">
																								<span className="mb-0 text-sm ">
																									{i + 1}
																								</span>
																							</th>
																							<td className=""> {category}</td>
																							<td>
																								<Button
																									type="button"
																									onClick={e => {
																										e.preventDefault();
																										this.addToEditQC(category);
																									}}
																									size="sm"
																									color="white"
																								>
																									<i className="ni ni-settings  " />
																								</Button>
																								<Button
																									type="button"
																									onClick={e => {
																										e.preventDefault();
																										this.deleteQuestionCategory(
																											category
																										);
																									}}
																									size="sm"
																									color="white"
																								>
																									<i className="fas fa-trash  " />
																								</Button>
																							</td>
																						</tr>
																					);
																				});
																		})}
															</tbody>
														</Table>
														<Row className="mt-2">
															<Col md={4} cols={12}>
																<Pagination aria-label="Page navigation example">
																	<PaginationItem>
																		<PaginationLink
																			className=" "
																			previous
																			disabled={this.state.paginationQC === 1}
																			onClick={e => {
																				e.preventDefault();
																				this.setState({
																					paginationQC:
																						this.state.paginationQC - 1,
																				});
																			}}
																		/>
																	</PaginationItem>
																	<PaginationItem>
																		<PaginationLink className=" " disabled>
																			{this.state.paginationQC}
																		</PaginationLink>
																	</PaginationItem>
																	<PaginationItem>
																		<PaginationLink
																			className=" "
																			next
																			disabled={
																				components &&
																				components
																					.filter(
																						comp =>
																							comp.id === "question_category"
																					)[0]
																					?.categories.filter(category => {
																						if (this.state.search) {
																							return category
																								.toLowerCase()
																								.includes(
																									this.state.search.toLowerCase()
																								);
																						} else {
																							return true;
																						}
																					}).length /
																				5 <=
																				this.state.paginationQC
																			}
																			onClick={e => {
																				e.preventDefault();
																				this.setState({
																					paginationQC:
																						this.state.paginationQC + 1,
																				});
																			}}
																		/>
																	</PaginationItem>
																</Pagination>
															</Col>
															<Col md={8} xs={12} className="text-left">
																<sub>
																	Page {this.state.paginationQC} of{" "}
																	<span>
																		{components &&
																			Math.ceil(
																				Math.floor(
																					components.filter(
																						comp =>
																							comp.id === "question_category"
																					)[0]?.categories.length
																				) / 5
																			)}
																	</span>
																</sub>
															</Col>
														</Row>
													</CardBody>
												</Card>
											</Col>
											{/* QUESTION COMPETENCY */}
											<Col xs={{ size: 12 }} lg={{ size: 6 }} className="mb-4">
												<Card className="shadow border-dark">
													<CardBody className=" ">
														<div style={{ display: "flex" }}>
															<h3 className=" mb-0 ">Question Competency</h3>
															<Link
																className="ml-2"
																to="/admin/assessmentcomponents/questioncompetency"
															>
																<Button color="white" size="sm">
																	<i className="fas fa-eye" />
																</Button>
															</Link>
														</div>
														<p className="text-info">
															Used to determine Question Competency
														</p>
														<Form>
															<InputGroup className="mb-3 ">
																<Input
																	type="select"
																	name="select"
																	id="exampleSelect"
																	onChange={e => {
																		this.setState({
																			QuestionCompetency: e.target.value,
																		});
																	}}
																>
																	<option value="Ascending">Ascending</option>
																	<option value="Descending">Descending</option>
																</Input>
															</InputGroup>
														</Form>
														<Form>
															<InputGroup className="mb-3 ">
																<Input
																	name="searchQCM"
																	className=" "
																	onChange={this.searchQCM}
																	placeholder="Search"
																/>
																<InputGroupAddon addonType="append">
																	<Button>
																		<i className="fas fa-search  " />
																	</Button>
																</InputGroupAddon>
															</InputGroup>
														</Form>
														<Form
															hidden={this.state.editQCM}
															onSubmit={this.editQuestionCompetency}
															className=""
														>
															<InputGroup className=" ">
																<Input
																	name="question_competency"
																	className=" "
																	value={this.state.competency}
																	onChange={e => {
																		e.preventDefault();
																		this.setState({
																			competency: e.target.value,
																		});
																	}}
																/>
																<InputGroupAddon addonType="append">
																	<Button>EDIT</Button>
																</InputGroupAddon>
															</InputGroup>
														</Form>
														<Form
															hidden={!this.state.editQCM}
															onSubmit={this.addQuestionCompetency}
															className=""
														>
															<InputGroup className=" ">
																<Input
																	name="question_competency"
																	className=" "
																	placeholder="Competency"
																	valid={
																		!this.state.question_competency_error &&
																		this.state.question_competency
																	}
																	invalid={this.state.question_competency_error}
																/>

																<InputGroupAddon addonType="append">
																	<Button>ADD</Button>
																</InputGroupAddon>
																<FormFeedback>
																	{this.state.question_competency_error}
																</FormFeedback>
															</InputGroup>
														</Form>
														<Table
															className="align-items-center  table-flush mt-4"
															responsive
														>
															<thead className="thread-light">
																<tr>
																	<th scope="col"> Id</th>
																	<th scope="col">Competency</th>
																	<th scope="col">Actions</th>
																</tr>
															</thead>
															<tbody>
																{components &&
																	// components
																	// .filter(
																	//   (comp) =>
																	//     comp.id === "question_competency"
																	// )
																	this.questionCompetencyFilter(
																		"question_competency"
																	).map(comp => {
																		return comp.competencies
																			.filter(competency => {
																				if (this.state.searchQCM) {
																					return competency
																						.toLowerCase()
																						.includes(
																							this.state.searchQCM.toLowerCase()
																						);
																				} else {
																					return true;
																				}
																			})
																			.splice(
																				5 * (this.state.paginationQCM - 1),
																				5
																			)

																			.map((competency, i) => {
																				return (
																					<tr
																						hidden={
																							this.state.changeCompetency ===
																							competency
																						}
																					>
																						<th scope="row">
																							<span className="mb-0 text-sm ">
																								{i + 1}
																							</span>
																						</th>
																						<td className=""> {competency}</td>
																						<td>
																							<Button
																								type="button"
																								onClick={e => {
																									e.preventDefault();
																									this.addToEditQCM(competency);
																								}}
																								size="sm"
																								color="white"
																							>
																								<i className="ni ni-settings  " />
																							</Button>
																							<Button
																								type="button"
																								onClick={e => {
																									e.preventDefault();
																									this.deleteQuestionCompetency(
																										competency
																									);
																								}}
																								size="sm"
																								color="white"
																							>
																								<i className="fas fa-trash  " />
																							</Button>
																						</td>
																					</tr>
																				);
																			});
																	})}
															</tbody>
														</Table>
														<Row className="mt-2">
															<Col md={4} cols={12}>
																<Pagination aria-label="Page navigation example">
																	<PaginationItem>
																		<PaginationLink
																			className=" "
																			previous
																			disabled={this.state.paginationQCM === 1}
																			onClick={e => {
																				e.preventDefault();
																				this.setState({
																					paginationQCM:
																						this.state.paginationQCM - 1,
																				});
																			}}
																		/>
																	</PaginationItem>
																	<PaginationItem>
																		<PaginationLink className=" " disabled>
																			{this.state.paginationQCM}
																		</PaginationLink>
																	</PaginationItem>
																	<PaginationItem>
																		<PaginationLink
																			className=" "
																			next
																			disabled={
																				components &&
																				components
																					.filter(
																						comp =>
																							comp.id === "question_competency"
																					)[0]
																					?.competencies.filter(competency => {
																						if (this.state.search) {
																							return competency
																								.toLowerCase()
																								.includes(
																									this.state.search.toLowerCase()
																								);
																						} else {
																							return true;
																						}
																					}).length /
																				5 <=
																				this.state.paginationQCM
																			}
																			onClick={e => {
																				e.preventDefault();
																				this.setState({
																					paginationQCM:
																						this.state.paginationQCM + 1,
																				});
																			}}
																		/>
																	</PaginationItem>
																</Pagination>
															</Col>
															<Col md={8} xs={12} className="text-left">
																<sub>
																	Page {this.state.paginationQCM} of{" "}
																	<span>
																		{components &&
																			Math.ceil(
																				Math.floor(
																					components.filter(
																						comp =>
																							comp.id === "question_competency"
																					)[0]?.competencies.length
																				) / 5
																			)}
																	</span>
																</sub>
															</Col>
														</Row>
													</CardBody>
												</Card>
											</Col>
											{/* DIMENSION */}
											<Col xs={{ size: 12 }} lg={{ size: 6 }} className="mb-4">
												<Card className="shadow border-dark ">
													<CardBody className=" ">
														<div style={{ display: "flex" }}>
															<h3 className=" mb-0 ">Dimension</h3>
															<Link
																className="ml-2"
																to="/admin/assessmentcomponents/questiondimension"
															>
																<Button color="white" size="sm">
																	<i className="fas fa-eye" />
																</Button>
															</Link>
														</div>
														<p className="text-info">
															Used to determine Question Dimension
														</p>
														<Form>
															<InputGroup className="mb-3 ">
																<Input
																	type="select"
																	name="select"
																	id="exampleSelect"
																	onChange={e => {
																		this.setState({
																			QuestionDimension: e.target.value,
																		});
																	}}
																>
																	<option value="Ascending">Ascending</option>
																	<option value="Descending">Descending</option>
																</Input>
															</InputGroup>
														</Form>
														<Form>
															<InputGroup className="mb-3 ">
																<Input
																	name="searchQD"
																	onChange={this.searchQD}
																	className=" "
																	placeholder="Search"
																/>
																<InputGroupAddon addonType="append">
																	<Button>
																		<i className="fas fa-search  " />
																	</Button>
																</InputGroupAddon>
															</InputGroup>
														</Form>
														<Form
															hidden={this.state.editQD}
															onSubmit={this.editQuestionDimension}
															className=""
														>
															<InputGroup className=" ">
																<Input
																	name="question_dimension"
																	className=" "
																	value={this.state.dimension}
																	onChange={e => {
																		e.preventDefault();
																		this.setState({
																			dimension: e.target.value,
																		});
																	}}
																/>
																<InputGroupAddon addonType="append">
																	<Button>EDIT</Button>
																</InputGroupAddon>
															</InputGroup>
														</Form>
														<Form
															hidden={!this.state.editQD}
															onSubmit={this.addQuestionDimension}
															className=""
														>
															<InputGroup className=" ">
																<Input
																	name="question_dimension"
																	className=" "
																	placeholder="Dimension"
																	valid={
																		!this.state.question_dimension_error &&
																		this.state.question_dimension
																	}
																	invalid={this.state.question_dimension_error}
																/>

																<InputGroupAddon addonType="append">
																	<Button>ADD</Button>
																</InputGroupAddon>
																<FormFeedback>
																	{this.state.question_dimension_error}
																</FormFeedback>
															</InputGroup>
														</Form>
														<Table
															className="align-items-center  table-flush mt-4"
															responsive
														>
															<thead className="thread-light">
																<tr>
																	<th scope="col"> Id</th>
																	<th scope="col">Dimension</th>
																	<th scope="col">Actions</th>
																</tr>
															</thead>
															<tbody>
																{components &&
																	// components
																	// .filter(
																	//   (comp) => comp.id === "question_dimension"
																	// )
																	this.questionDimensionFilter(
																		"question_dimension"
																	).map(comp => {
																		return comp.dimensions
																			.filter(dimension => {
																				if (this.state.searchQD) {
																					return dimension
																						.toLowerCase()
																						.includes(
																							this.state.searchQD.toLowerCase()
																						);
																				} else {
																					return true;
																				}
																			})
																			.splice(
																				5 * (this.state.paginationQD - 1),
																				5
																			)

																			.map((dimension, i) => {
																				return (
																					<tr
																						hidden={
																							this.state.changeDimension ===
																							dimension
																						}
																					>
																						<th scope="row">
																							<span className="mb-0 text-sm ">
																								{i + 1}
																							</span>
																						</th>
																						<td className=""> {dimension}</td>
																						<td>
																							<Button
																								type="button"
																								onClick={e => {
																									e.preventDefault();
																									this.addToEditQD(dimension);
																								}}
																								size="sm"
																								color="white"
																							>
																								<i className="ni ni-settings  " />
																							</Button>
																							<Button
																								type="button"
																								onClick={e => {
																									e.preventDefault();
																									this.deleteQuestionDimension(
																										dimension
																									);
																								}}
																								size="sm"
																								color="white"
																							>
																								<i className="fas fa-trash  " />
																							</Button>
																						</td>
																					</tr>
																				);
																			});
																	})}
															</tbody>
														</Table>
														<Row className="mt-2">
															<Col md={4} cols={12}>
																<Pagination aria-label="Page navigation example">
																	<PaginationItem>
																		<PaginationLink
																			className=" "
																			previous
																			disabled={this.state.paginationQD === 1}
																			onClick={e => {
																				e.preventDefault();
																				this.setState({
																					paginationQD:
																						this.state.paginationQD - 1,
																				});
																			}}
																		/>
																	</PaginationItem>
																	<PaginationItem>
																		<PaginationLink className=" " disabled>
																			{this.state.paginationQD}
																		</PaginationLink>
																	</PaginationItem>
																	<PaginationItem>
																		<PaginationLink
																			className=" "
																			next
																			disabled={
																				components &&
																				components
																					.filter(
																						comp =>
																							comp.id === "question_dimension"
																					)[0]
																					?.dimensions.filter(dimension => {
																						if (this.state.search) {
																							return dimension
																								.toLowerCase()
																								.includes(
																									this.state.search.toLowerCase()
																								);
																						} else {
																							return true;
																						}
																					}).length /
																				5 <=
																				this.state.paginationQD
																			}
																			onClick={e => {
																				e.preventDefault();
																				this.setState({
																					paginationQD:
																						this.state.paginationQD + 1,
																				});
																			}}
																		/>
																	</PaginationItem>
																</Pagination>
															</Col>
															<Col md={5} cols={12} className="text-left">
																<sub>
																	Page {this.state.paginationQD} of{" "}
																	<span>
																		{components &&
																			Math.ceil(
																				Math.floor(
																					components.filter(
																						comp =>
																							comp.id === "question_dimension"
																					)[0]?.dimensions.length
																				) / 5
																			)}
																	</span>
																</sub>
															</Col>
														</Row>
													</CardBody>
												</Card>
											</Col>
											{/* CLIENT INDUSTRY */}
											<Col xs={{ size: 12 }} lg={{ size: 6 }} className="mb-4">
												<Card className="shadow border-dark ">
													<CardBody className=" ">
														<div style={{ display: "flex" }}>
															<h3 className=" mb-0 ">Client Industry</h3>
															<Link
																className="ml-2"
																to="/admin/assessmentcomponents/clientindustry"
															>
																<Button color="white" size="sm">
																	<i className="fas fa-eye" />
																</Button>
															</Link>
														</div>
														<p className="text-info">
															Used to determine Client Industry
														</p>
														<Form>
															<InputGroup className="mb-3 ">
																<Input
																	type="select"
																	name="select"
																	id="exampleSelect"
																	onChange={e => {
																		this.setState({
																			ClientIndustry: e.target.value,
																		});
																	}}
																>
																	<option value="Ascending">Ascending</option>
																	<option value="Descending">Descending</option>
																</Input>
															</InputGroup>
														</Form>
														<Form>
															<InputGroup className="mb-3 ">
																<Input
																	name="search"
																	onChange={this.search}
																	className=" "
																	placeholder="Search"
																/>
																<InputGroupAddon addonType="append">
																	<Button>
																		<i className="fas fa-search  " />
																	</Button>
																</InputGroupAddon>
															</InputGroup>
														</Form>
														<Form
															hidden={this.state.edit}
															onSubmit={this.editClientIndustry}
															className=""
														>
															<InputGroup className=" ">
																<Input
																	name="client_industry"
																	className=" "
																	value={this.state.industry}
																	onChange={e => {
																		e.preventDefault();
																		this.setState({ industry: e.target.value });
																	}}
																/>
																<InputGroupAddon addonType="append">
																	<Button>EDIT</Button>
																</InputGroupAddon>
															</InputGroup>
														</Form>
														<Form
															hidden={!this.state.edit}
															onSubmit={this.addClientIndustry}
															className=""
														>
															<InputGroup className=" ">
																<Input
																	name="client_industry"
																	className=" "
																	placeholder="Industry"
																	valid={
																		!this.state.client_industry_error &&
																		this.state.client_industry
																	}
																	invalid={this.state.client_industry_error}
																/>

																<InputGroupAddon addonType="append">
																	<Button>ADD</Button>
																</InputGroupAddon>
																<FormFeedback>
																	{this.state.client_industry_error}
																</FormFeedback>
															</InputGroup>
														</Form>
														<Table
															className="align-items-center  table-flush mt-4"
															responsive
														>
															<thead className="thread-light">
																<tr>
																	<th scope="col"> Id</th>
																	<th scope="col">Industry</th>
																	<th scope="col">Actions</th>
																</tr>
															</thead>
															<tbody>
																{components &&
																	// components
																	this.clientIndustryFilter("client_industry")
																		// .filter(
																		//   (comp) => comp.id === "client_industry"
																		// )
																		.map(comp => {
																			return comp.industries
																				.filter(industry => {
																					if (this.state.search) {
																						return industry
																							.toLowerCase()
																							.includes(
																								this.state.search.toLowerCase()
																							);
																					} else {
																						return true;
																					}
																				})
																				.splice(
																					5 * (this.state.pagination - 1),
																					5
																				)

																				.map((industry, i) => {
																					return (
																						<tr
																							hidden={
																								this.state.changeIndustry ===
																								industry
																							}
																						>
																							<th scope="row">
																								<span className="mb-0 text-sm ">
																									{i + 1}
																								</span>
																							</th>
																							<td className=""> {industry}</td>
																							<td>
																								<Button
																									type="button"
																									onClick={e => {
																										e.preventDefault();
																										this.addToEdit(industry);
																									}}
																									size="sm"
																									color="white"
																								>
																									<i className="ni ni-settings  " />
																								</Button>
																								<Button
																									type="button"
																									onClick={e => {
																										e.preventDefault();
																										this.deleteClientIndustry(
																											industry
																										);
																									}}
																									size="sm"
																									color="white"
																								>
																									<i className="fas fa-trash  " />
																								</Button>
																							</td>
																						</tr>
																					);
																				});
																		})}
															</tbody>
														</Table>
														<Row className="mt-2">
															<Col md={4} cols={12}>
																<Pagination aria-label="Page navigation example">
																	<PaginationItem>
																		<PaginationLink
																			className=" "
																			previous
																			disabled={this.state.pagination === 1}
																			onClick={e => {
																				e.preventDefault();
																				this.setState({
																					pagination: this.state.pagination - 1,
																				});
																			}}
																		/>
																	</PaginationItem>
																	<PaginationItem>
																		<PaginationLink className=" " disabled>
																			{this.state.pagination}
																		</PaginationLink>
																	</PaginationItem>
																	<PaginationItem>
																		<PaginationLink
																			className=" "
																			next
																			disabled={
																				components &&
																				components
																					.filter(
																						comp =>
																							comp.id === "client_industry"
																					)[0]
																					?.industries.filter(industry => {
																						if (this.state.search) {
																							return industry
																								.toLowerCase()
																								.includes(
																									this.state.search.toLowerCase()
																								);
																						} else {
																							return true;
																						}
																					}).length /
																				5 <=
																				this.state.pagination
																			}
																			onClick={e => {
																				e.preventDefault();
																				this.setState({
																					pagination: this.state.pagination + 1,
																				});
																			}}
																		/>
																	</PaginationItem>
																</Pagination>
															</Col>
															<Col md={5} cols={12} className="text-left">
																<sub>
																	Page {this.state.pagination} of{" "}
																	<span>
																		{components &&
																			Math.ceil(
																				Math.floor(
																					components.filter(
																						comp =>
																							comp.id === "client_industry"
																					)[0]?.industries.length
																				) / 5
																			)}
																	</span>
																</sub>
															</Col>
														</Row>
													</CardBody>
												</Card>
											</Col>
											{/* RATER ROLES */}
											<Col xs={{ size: 12 }} lg={{ size: 6 }} className="mb-4">
												<Card className="shadow border-dark ">
													<CardBody className=" ">
														<div style={{ display: "flex" }}>
															<h3 className=" mb-0 ">Rater Roles</h3>
															<Link
																className="ml-2"
																to="/admin/assessmentcomponents/rateroles"
															>
																<Button color="white" size="sm">
																	<i className="fas fa-eye" />
																</Button>
															</Link>
														</div>
														<p className="text-info">
															Used to determine Rater Roles
														</p>
														<Form>
															<InputGroup className="mb-3 ">
																<Input
																	type="select"
																	name="select"
																	id="exampleSelect"
																	onChange={e => {
																		this.setState({
																			RaterRole: e.target.value,
																		});
																	}}
																>
																	<option value="Ascending">Ascending</option>
																	<option value="Descending">Descending</option>
																</Input>
															</InputGroup>
														</Form>
														<Form>
															<InputGroup className="mb-3 ">
																<Input
																	name="search"
																	onChange={this.searchRole}
																	className=" "
																	placeholder="Search"
																/>
																<InputGroupAddon addonType="append">
																	<Button>
																		<i className="fas fa-search  " />
																	</Button>
																</InputGroupAddon>
															</InputGroup>
														</Form>
														<Form
															hidden={this.state.editRole}
															onSubmit={this.editRaterRole}
															className=""
														>
															<InputGroup className=" ">
																<Input
																	name="rater_role"
																	className=" "
																	value={this.state.role}
																	onChange={e => {
																		e.preventDefault();
																		this.setState({
																			role: e.target.value,
																		});
																	}}
																/>
																<InputGroupAddon addonType="append">
																	<Button>EDIT</Button>
																</InputGroupAddon>
															</InputGroup>
														</Form>
														<Form
															hidden={!this.state.editRole}
															onSubmit={this.addRaterRole}
															className=""
														>
															<InputGroup className=" ">
																<Input
																	name="rater_role"
																	className=" "
																	placeholder="Rater Role"
																	valid={
																		!this.state.rater_role_error &&
																		this.state.role
																	}
																	invalid={this.state.rater_role_error}
																/>

																<InputGroupAddon addonType="append">
																	<Button>ADD</Button>
																</InputGroupAddon>
																<FormFeedback>
																	{this.state.rater_role_error}
																</FormFeedback>
															</InputGroup>
														</Form>
														<Table
															className="align-items-center  table-flush mt-4"
															responsive
														>
															<thead className="thread-light">
																<tr>
																	<th scope="col"> Id</th>
																	<th scope="col">Role</th>
																	<th scope="col">Actions</th>
																</tr>
															</thead>
															<tbody>
																{components &&
																	// components
																	this.raterRolesFilter("rater_roles")
																		.map(comp => {
																			return comp.role
																				.filter(industry => {
																					if (this.state.searchRole) {
																						return industry
																							.toLowerCase()
																							.includes(
																								this.state.searchRole.toLowerCase()
																							);
																					} else {
																						return true;
																					}
																				})
																				.splice(
																					5 * (this.state.paginationRole - 1),
																					5
																				)

																				.map((role, i) => {
																					return (
																						<tr
																							hidden={
																								this.state.changeIndustry ===
																								role
																							}
																						>
																							<th scope="row">
																								<span className="mb-0 text-sm ">
																									{i + 1}
																								</span>
																							</th>
																							<td className=""> {role}</td>
																							<td>
																								<Button
																									type="button"
																									onClick={e => {
																										e.preventDefault();
																										this.addToEditRole(role);
																									}}
																									size="sm"
																									color="white"
																								>
																									<i className="ni ni-settings  " />
																								</Button>
																								<Button
																									type="button"
																									onClick={e => {
																										e.preventDefault();
																										this.deleteRaterRole(role);
																									}}
																									size="sm"
																									color="white"
																								>
																									<i className="fas fa-trash  " />
																								</Button>
																							</td>
																						</tr>
																					);
																				});
																		})}
															</tbody>
														</Table>
														<Row className="mt-2">
															<Col md={4} cols={12}>
																<Pagination aria-label="Page navigation example">
																	<PaginationItem>
																		<PaginationLink
																			className=" "
																			previous
																			disabled={this.state.paginationRole === 1}
																			onClick={e => {
																				e.preventDefault();
																				this.setState({
																					paginationRole:
																						this.state.paginationRole - 1,
																				});
																			}}
																		/>
																	</PaginationItem>
																	<PaginationItem>
																		<PaginationLink className=" " disabled>
																			{this.state.paginationRole}
																		</PaginationLink>
																	</PaginationItem>
																	<PaginationItem>
																		<PaginationLink
																			className=" "
																			next
																			disabled={
																				components &&
																				components
																					.find(
																						comp => comp.id === "rater_roles"
																					)
																					?.role.filter(industry => {
																						if (this.state.searchRole) {
																							return industry
																								.toLowerCase()
																								.includes(
																									this.state.searchRole.toLowerCase()
																								);
																						} else {
																							return true;
																						}
																					}).length /
																				5 <=
																				this.state.paginationRole
																			}
																			onClick={e => {
																				e.preventDefault();
																				this.setState({
																					paginationRole:
																						this.state.paginationRole + 1,
																				});
																			}}
																		/>
																	</PaginationItem>
																</Pagination>
															</Col>
															<Col md={5} cols={12} className="text-left">
																<sub>
																	Page {this.state.paginationRole} of{" "}
																	<span>
																		{components &&
																			Math.ceil(
																				Math.floor(
																					components.find(
																						comp => comp.id === "rater_roles"
																					)?.role.length
																				) / 5
																			)}
																	</span>
																</sub>
															</Col>
														</Row>
													</CardBody>
												</Card>
											</Col>
											{/* RATER RELATIONSHIP */}
											<Col xs={{ size: 12 }} lg={{ size: 6 }} className="mb-4">
												<Card className="shadow border-dark ">
													<CardBody className=" ">
														<div style={{ display: "flex" }}>
															<h3 className=" mb-0 ">Rater Relationship</h3>
															{/* <Link
																className="ml-2"
																to="/admin/assessmentcomponents/rateroles"
															>
																<Button color="white" size="sm">
																	<i className="fas fa-eye" />
																</Button>
															</Link> */}
														</div>
														<p className="text-info">
															Used to determine Rater relationship
														</p>
														<Form>
															<InputGroup className="mb-3 ">
																<Input
																	type="select"
																	name="select"
																	id="exampleSelect"
																	onChange={e => {
																		this.setState({
																			RaterRelationship: e.target.value,
																		});
																	}}
																>
																	<option value="Ascending">Ascending</option>
																	<option value="Descending">Descending</option>
																</Input>
															</InputGroup>
														</Form>
														<Form>
															<InputGroup className="mb-3 ">
																<Input
																	name="search"
																	onChange={this.searchRelationship}
																	className=" "
																	placeholder="Search"
																/>
																<InputGroupAddon addonType="append">
																	<Button>
																		<i className="fas fa-search  " />
																	</Button>
																</InputGroupAddon>
															</InputGroup>
														</Form>
														<Form
															hidden={this.state.editRelationship}
															onSubmit={this.editRaterRelationship}
															className=""
														>
															<InputGroup className=" ">
																<Input
																	name="rater_relationship"
																	className=" "
																	value={this.state.relationship}
																	onChange={e => {
																		e.preventDefault();
																		this.setState({
																			relationship: e.target.value,
																		});
																	}}
																/>
																<InputGroupAddon addonType="append">
																	<Button>EDIT</Button>
																</InputGroupAddon>
															</InputGroup>
														</Form>
														<Form
															hidden={!this.state.editRelationship}
															onSubmit={this.addRaterRelationship}
															className=""
														>
															<InputGroup className=" ">
																<Input
																	name="rater_relationship"
																	className=" "
																	placeholder="Rater Relationship"
																	valid={
																		!this.state.rater_relationship_error &&
																		this.state.relationship
																	}
																	invalid={this.state.rater_relationship_error}
																/>

																<InputGroupAddon addonType="append">
																	<Button>ADD</Button>
																</InputGroupAddon>
																<FormFeedback>
																	{this.state.rater_relationship_error}
																</FormFeedback>
															</InputGroup>
														</Form>
														<Table
															className="align-items-center  table-flush mt-4"
															responsive
														>
															<thead className="thread-light">
																<tr>
																	<th scope="col"> Id</th>
																	<th scope="col">Relationship</th>
																	<th scope="col">Actions</th>
																</tr>
															</thead>
															<tbody>
																{components &&
																	// components
																	this.raterRelationshipsFilter("rater_relationships")
																		.map(comp => {
																			return comp.relationship
																				.filter(relation => {
																					if (this.state.searchRelationship) {
																						return relation
																							.toLowerCase()
																							.includes(
																								this.state.searchRelationship.toLowerCase()
																							);
																					} else {
																						return true;
																					}
																				})
																				.splice(
																					5 * (this.state.paginationRole - 1),
																					5
																				)

																				.map((relationship, i) => {
																					return (
																						<tr
																							hidden={
																								this.state.changeIndustry ===
																								relationship
																							}
																						>
																							<th scope="row">
																								<span className="mb-0 text-sm ">
																									{i + 1}
																								</span>
																							</th>
																							<td className=""> {relationship}</td>
																							<td>
																								<Button
																									type="button"
																									onClick={e => {
																										e.preventDefault();
																										this.addToEditRelationship(relationship);
																									}}
																									size="sm"
																									color="white"
																								>
																									<i className="ni ni-settings  " />
																								</Button>
																								<Button
																									type="button"
																									onClick={e => {
																										e.preventDefault();
																										this.props.deleteRaterRelationship(relationship);
																									}}
																									size="sm"
																									color="white"
																								>
																									<i className="fas fa-trash  " />
																								</Button>
																							</td>
																						</tr>
																					);
																				});
																		})}
															</tbody>
														</Table>
														<Row className="mt-2">
															<Col md={4} cols={12}>
																<Pagination aria-label="Page navigation example">
																	<PaginationItem>
																		<PaginationLink
																			className=" "
																			previous
																			disabled={this.state.paginationRole === 1}
																			onClick={e => {
																				e.preventDefault();
																				this.setState({
																					paginationRole:
																						this.state.paginationRole - 1,
																				});
																			}}
																		/>
																	</PaginationItem>
																	<PaginationItem>
																		<PaginationLink className=" " disabled>
																			{this.state.paginationRole}
																		</PaginationLink>
																	</PaginationItem>
																	<PaginationItem>
																		<PaginationLink
																			className=" "
																			next
																			disabled={
																				components &&
																				components
																					.find(
																						comp => comp.id === "rater_relationships"
																					)
																					?.relationship.filter(relation => {
																						if (this.state.searchRelationship) {
																							return relation
																								.toLowerCase()
																								.includes(
																									this.state.searchRelationship.toLowerCase()
																								);
																						} else {
																							return true;
																						}
																					}).length /
																				5 <=
																				this.state.paginationRole
																			}
																			onClick={e => {
																				e.preventDefault();
																				this.setState({
																					paginationRole:
																						this.state.paginationRole + 1,
																				});
																			}}
																		/>
																	</PaginationItem>
																</Pagination>
															</Col>
															<Col md={5} cols={12} className="text-left">
																<sub>
																	Page {this.state.paginationRole} of{" "}
																	<span>
																		{components &&
																			Math.ceil(
																				Math.floor(
																					components.find(
																						comp => comp.id === "rater_relationships"
																					)?.relationship.length
																				) / 5
																			)}
																	</span>
																</sub>
															</Col>
														</Row>
													</CardBody>
												</Card>
											</Col>
										</Row>
									</CardBody>
								</Card>
							</Col>
						</Row>
					</Container>
				</>
			);
		}
	}
}
const mapStateToProps = state => {
	return {
		uid: state.firebase.auth.uid,
		components: state.firestore.ordered.components,
		assessments: state.firestore.ordered.assessments,
		clients: state.firestore.ordered.clients,
	};
};
const mapDispatchToProps = dispatch => {
	return {
		addClientIndustry: industry => dispatch(addClientIndustry(industry)),
		deleteClientIndustry: industry => dispatch(deleteClientIndustry(industry)),
		editClientIndustry: (industry, newIndustry) =>
			dispatch(editClientIndustry(industry, newIndustry)),
		addQuestionDimension: dimension =>
			dispatch(addQuestionDimension(dimension)),
		deleteQuestionDimension: dimension =>
			dispatch(deleteQuestionDimension(dimension)),
		editQuestionDimension: (dimension, newDimension) =>
			dispatch(editQuestionDimension(dimension, newDimension)),
		addQuestionCategory: category => dispatch(addQuestionCategory(category)),
		deleteQuestionCategory: category =>
			dispatch(deleteQuestionCategory(category)),
		editQuestionCategory: (category, newCategory) =>
			dispatch(editQuestionCategory(category, newCategory)),
		addQuestionType: type => dispatch(addQuestionType(type)),
		deleteQuestionType: type => dispatch(deleteQuestionType(type)),
		editQuestionType: (type, newType) =>
			dispatch(editQuestionType(type, newType)),
		addAssessmentType: type => dispatch(addAssessmentType(type)),
		deleteAssessmentType: type => dispatch(deleteAssessmentType(type)),
		editAssessmentType: (type, newType) =>
			dispatch(editAssessmentType(type, newType)),
		addQuestionCompetency: competency =>
			dispatch(addQuestionCompetency(competency)),
		deleteQuestionCompetency: competency =>
			dispatch(deleteQuestionCompetency(competency)),
		editQuestionCompetency: (competency, newCompetency) =>
			dispatch(editQuestionCompetency(competency, newCompetency)),
		addRaterRole: role => dispatch(addRaterRole(role)),
		deleteRaterRole: role => dispatch(deleteRaterRole(role)),
		editRaterRole: (role, newRole) => dispatch(editRaterRole(role, newRole)),
		addRaterRelationship: relationship => dispatch(addRaterRelationship(relationship)),
		deleteRaterRelationship: relationship => dispatch(deleteRaterRelationship(relationship)),
		editRaterRelationship: (relationship, newRelationship) => dispatch(editRaterRelationship(relationship, newRelationship)),
	};
};

export default compose(
	firestoreConnect(["components", "assessments", "clients"]),
	connect(mapStateToProps, mapDispatchToProps)
)(AssessmentComponents);
