import Index from "views/Index.js";

import Admins from "views/examples/Admins.js";
import Assessments from "views/examples/Assessments.js";
import ConsultingCompanies from "views/examples/ConsultingCompanies.js";
import Consultants from "views/examples/Consultants.js";
import AssessmentComponents from "views/examples/AssessmentComponents";
import ConsultantIndustry from "views/examples/ConsultantIndustry";
import CreateEmailTemplate from 'views/examples/CreateEmailTemplate';
import EmailTemplate from 'views/examples/EmailTemplate';
import EditEmailTemplate from "views/examples/EditEmailTemplate";
import Language from "views/examples/Language";

var routes = [
	{
		path: '/index',
		name: 'Dashboard',
		icon: 'ni ni-tv-2',
		component: Index,
		layout: '/admin',
	},
	{
		path: '/admins',
		name: 'Admins',
		icon: 'ni ni-planet',
		component: Admins,
		layout: '/admin',
	},
	{
		path: '/assessments',
		name: 'Assessments',
		icon: 'ni ni-pin-3',
		component: Assessments,
		layout: '/admin',
	},
	{
		path: '/assessmentcomponents',
		name: 'Components',
		dropdown: [
			{
				path: '/assessmenttype',
				name: 'Assessment Type',
			},
			{
				path: '/questiontype',
				name: 'Question Type',
			},
			{
				path: '/questioncategory',
				name: 'Question Category',
			},
			{
				path: '/questioncompetency',
				name: 'Question Competency',
			},

			{
				path: '/questiondimension',
				name: 'Question Dimension',
			},
			{
				path: '/clientindustry',
				name: 'Client Industry',
			},
			{
				path: '/rateroles',
				name: 'Rate Roles',
			},
		],
		icon: 'ni ni-settings',
		component: AssessmentComponents,
		layout: '/admin',
	},
	{
		path: '/consultantindustry',
		name: 'Consultant Industries',
		icon: 'ni ni-building',
		component: ConsultantIndustry,
		layout: '/admin',
	},
	{
		path: '/consulting-companies',
		name: 'Consulting Companies',
		icon: 'ni ni-single-02',
		component: ConsultingCompanies,
		layout: '/admin',
	},
	{
		path: '/consultants',
		name: 'Consultants',
		icon: 'ni ni-bullet-list-67',
		component: Consultants,
		layout: '/admin',
	},
	{
		path: '/email-templates',
		name: 'Email Templates',
		icon: 'fa fa-envelope',
		component: EmailTemplate,
		layout: '/admin',
	},
	{
		path: '/language',
		name: 'Language',
		icon: 'fa fa-language',
		component: Language,
		layout: '/admin',
	},
	{
		path: '/email-templates/create',
		name: 'Email Templates',
		icon: 'fa fa-envelope',
		component: CreateEmailTemplate,
		layout: '/admin',
		menu: false,
	},
	{
		path: '/email-templates/edit',
		name: 'Email Templates',
		icon: 'fa fa-envelope',
		component: EditEmailTemplate,
		layout: '/admin',
		menu: false,
	},
];
export default routes;
