import moment from "moment";

export function require2FA(props) {
  const last2FA = localStorage.getItem("last2FA")

  if (!last2FA) return true;

  const last2FADuration = moment().diff(moment(last2FA), "minute")
  return (last2FADuration > 8 * 60)
}
