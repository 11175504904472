const initialState = {
    templates : [],
    created:false,
}

const templateReducer = (state = initialState, action) => {
    let {type,payload} = action;
    switch (type) {
		case 'FETCH_TEMPLATES':
			return {
				...state,
				templates: payload,
			};
		case 'CREATED_TEMPLATE':
			return {
				...state,
				created: true,
			};
		case 'EDIT_TEMPLATE':
			return {
				...state,
				created: true,
			};
		case 'RESET_CREATED':
			return {
				...state,
				created: false,
			};
		default:
			return {
				...state,
			};
	}
}

export default templateReducer;