import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components
import AuthNavbar from "components/Navbars/AuthNavbar.js";

import { compose } from "redux";
import { firestoreConnect } from "react-redux-firebase";
import { connect } from "react-redux";
import TwoFactorAuth from "views/examples/TwoFactorAuth";
import Login from "views/examples/Login";
import { require2FA } from "utils/storage";

class Auth extends React.Component {
	componentDidMount() {
		document.body.classList.add("bg-default");
	}
	componentWillUnmount() {
		document.body.classList.remove("bg-default");
	}

	render() {
		return (
			<>
				<div className="main-content">
					<AuthNavbar />
					<div className="header bg-gradient-info py-7 py-lg-8">
						<Container>
							<div className="header-body text-center mb-7">
								<Row className="justify-content-center">
									<Col lg="5" md="6">
										<h1 className="text-white">
											Welcome to Integral Assessment <b>Admin Panel</b>!
										</h1>
									</Col>
								</Row>
							</div>
						</Container>
						<div className="separator separator-bottom separator-skew zindex-100">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								preserveAspectRatio="none"
								version="1.1"
								viewBox="0 0 2560 100"
								x="0"
								y="0"
							>
								<polygon
									className="fill-default"
									points="2560 0 2560 100 0 100"
								/>
							</svg>
						</div>
					</div>
					{/* Page content */}
					<Container className="mt--8 pb-5">
						<Row className="justify-content-center">
							<Switch>
								<Route
									path={"/auth/login"}
									component={Login}
									key={"login"}
								/>
								<Route
									path={"/auth/2fa"}
									component={TwoFactorAuth}
									key={"2fa"}
								/>
								{require2FA() && <Redirect to="/auth/2fa" />}
							</Switch>
						</Row>
					</Container>
				</div>
			</>
		);
	}
}

const mapStateToProps = state => {
	return {
		uid: state.firebase.auth.uid,
	};
};
const mapDispatchToProps = dispatch => {
	return {};
};

export default compose(
	firestoreConnect([{ collection: "users" }]),
	connect(mapStateToProps, mapDispatchToProps)
)(Auth);
