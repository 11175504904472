import React from "react";

import {
	Card,
	CardHeader,
	Media,
	Table,
	Container,
	Row,
	Col,
	Button,
	Form,
	Label,
	Input,
	InputGroup,
	InputGroupAddon,
	FormFeedback,
	UncontrolledTooltip,
} from "reactstrap";
// core components
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import Header from "components/Headers/Header.js";
import { compose } from "redux";
import { firestoreConnect } from "react-redux-firebase";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import {
	addConsultant,
	deleteConsultant,
	editConsultant,
} from "actions/consultantActions";
import { resetEmailPassword } from "actions/adminActions";

class Consultants extends React.Component {
	state = { modal: false, editModal: "", search: "" };
	toggle = e => {
		e.preventDefault();
		this.setState({ modal: !this.state.modal });
	};
	editToggle = value => {
		if (this.state.editModal !== value) {
			this.setState({ editModal: value });
		} else {
			this.setState({ editModal: "" });
		}
	};
	getCompanyname(id) {
		var companies = this.props.companies;
		if (companies !== null) {
			let obj = companies.find(item => item.id === id);
			if (obj !== null) {
				return obj;
			} else {
				return {
					name: "",
				};
			}
		} else {
			return {
				name: "",
			};
		}
	}
	searchResults() {
		if (this.state.search !== "") {
			let pc = this.props.consultants.filter(
				item =>
					item.first_name
						.toLowerCase()
						.includes(this.state.search.toLowerCase()) ||
					item.last_name.toLowerCase().includes(this.state.search.toLowerCase())
			);
			return pc;
		} else {
			return this.props.consultants;
		}
	}
	editConsultant = e => {
		e.preventDefault();
		var error = false;
		if (!e.target.first_name.value) {
			this.setState({ first_name_error: "Please Add a First Name" });
			error = true;
		} else {
			this.setState({
				first_name_error: "",
				first_name: e.target.first_name.value,
			});
		}
		if (!e.target.last_name.value) {
			this.setState({ last_name_error: "Please Add a Last Name" });
			error = true;
		} else {
			this.setState({
				last_name_error: "",
				last_name: e.target.last_name.value,
			});
		}
		if (!e.target.title.value) {
			this.setState({ title_error: "Please Add a Title" });
			error = true;
		} else {
			this.setState({
				title_error: "",
				title: e.target.title.value,
			});
		}
		if (!e.target.email.value) {
			this.setState({ email_error: "Please Add a Title" });
			error = true;
		} else {
			this.setState({
				email_error: "",
				email: e.target.email.value,
			});
		}
		if (e.target.company.value === "Company") {
			this.setState({ company_error: "Please Select a Company" });
			error = true;
		} else {
			this.setState({
				company_error: "",
				company: e.target.company.value,
			});
		}

		if (!error) {

			// e.preventDefault();
			const consultant = {
				first_name: e.target.first_name.value,
				last_name: e.target.last_name.value,
				title: e.target.title.value,
				email: e.target.email.value,
				company: e.target.company.value,
			};
			this.props.editConsultant({
				id: this.state.editModal,
				payload: consultant,
			});
			this.setState({
				title: "",
				title_error: "",
				first_name: "",
				first_name_error: "",
				last_name: "",
				last_name_error: "",
				email: "",
				email_error: "",
				editModal: "",
			});
		}
	};
	addConsultant = e => {
		e.preventDefault();
		var error = false;
		if (!e.target.first_name.value) {
			this.setState({ first_name_error: "Please Add a First Name" });
			error = true;
		} else {
			this.setState({
				first_name_error: "",
				first_name: e.target.first_name.value,
			});
		}
		if (!e.target.last_name.value) {
			this.setState({ last_name_error: "Please Add a Last Name" });
			error = true;
		} else {
			this.setState({
				last_name_error: "",
				last_name: e.target.last_name.value,
			});
		}
		if (!e.target.title.value) {
			this.setState({ title_error: "Please Add a Title" });
			error = true;
		} else {
			this.setState({
				title_error: "",
				title: e.target.title.value,
			});
		}
		if (e.target.company.value === "Company") {
			this.setState({ company_error: "Please Select a Company" });
			error = true;
		} else {
			this.setState({
				company_error: "",
				company: e.target.company.value,
			});
		}

		if (
			!e.target.email.value ||
			!e.target.email.value.includes("@") ||
			!e.target.email.value.includes(".")
		) {
			this.setState({ email_error: "Invalid email" });
			error = true;
		} else {
			this.setState({ email_error: "", email: e.target.email.value });
		}

		if (!error) {
			e.preventDefault();
			let company_name = this.getCompanyname(e.target.company.value).name;

			const consultant = {
				first_name: e.target.first_name.value,
				last_name: e.target.last_name.value,
				title: e.target.title.value,
				company: e.target.company.value,
				email: e.target.email.value,
				status: "active",
				companyName: company_name,
			};
			let len = this.props.consultants.filter(
				item => item.email === e.target.email.value
			);
			if (len.length > 0) {
				alert("Consultant already exists with this email");
			} else {
				this.props.addConsultant(consultant);
				this.toggle(e);
				this.setState({
					title: "",
					title_error: "",
					first_name: "",
					first_name_error: "",
					last_name: "",
					last_name_error: "",
					email: "",
					email_error: "",

				});
			}
		}
	};
	render() {
		const { uid, companies, consultants } = this.props;
		if (!uid) {
			return <Redirect to="/auth/login" />;
		} else {
			return (
				<>
					<Header />
					{/* Page content */}
					<Container className="mt--9" fluid>
						<Row>
							<Col className="text-right">
								<Button
									className=" mb-2"
									color="white"
									href="#pablo"
									onClick={this.toggle}
									size="md"
								>
									Add Consultant
								</Button>
								<Modal isOpen={this.state.modal} toggle={this.toggle}>
									<ModalHeader toggle={this.toggle}>Add Consultant</ModalHeader>
									<ModalBody>
										<Form onSubmit={this.addConsultant}>
											<Label>First Name</Label>
											<Input
												name="first_name"
												valid={
													!this.state.first_name_error && this.state.first_name
												}
												invalid={this.state.first_name_error}
											/>
											<FormFeedback>{this.state.first_name_error}</FormFeedback>
											<Label>Last Name</Label>
											<Input
												name="last_name"
												valid={
													!this.state.last_name_error && this.state.last_name
												}
												invalid={this.state.last_name_error}
											/>
											<FormFeedback>{this.state.last_name_error}</FormFeedback>

											<Label>Role</Label>
											<Input
												name="title"
												valid={!this.state.title_error && this.state.title}
												invalid={this.state.title_error}
											/>
											<FormFeedback>{this.state.title_error}</FormFeedback>

											<Label>Company</Label>
											<Input
												name="company"
												type="select"
												valid={!this.state.company_error && this.state.company}
												invalid={this.state.company_error}
											>
												<option hidden>Company</option>
												{companies &&
													companies.map(company => {
														return (
															<option value={company.id}>{company.name}</option>
														);
													})}
											</Input>
											<FormFeedback>{this.state.company_error}</FormFeedback>
											<Label>Email</Label>
											<Input
												name="email"
												type="email"
												valid={!this.state.email_error && this.state.email}
												invalid={this.state.email_error}
											/>
											<FormFeedback>{this.state.email_error}</FormFeedback>



											<Row>
												<Col className="text-right">
													<Button className=" mt-2" color="default" size="md">
														Add Consultant
													</Button>
												</Col>
											</Row>
										</Form>
									</ModalBody>
								</Modal>
							</Col>
						</Row>
						{/* Dark table */}
						<Row>
							<div className="col">
								<Card className=" shadow p-2">
									<CardHeader className=" border-0">
										<h3 className=" mb-0">Consultants</h3>
									</CardHeader>
									<Form>
										<InputGroup className="mb-3 ">
											<Input
												onChange={e => {
													this.setState({ search: e.target.value });
												}}
												name="search"
												className=" "
												placeholder="Search"
											/>
											<InputGroupAddon addonType="append">
												<Button>
													<i className="fas fa-search  " />
												</Button>
											</InputGroupAddon>
										</InputGroup>
									</Form>
									<Table className="align-items-center  table-flush" responsive>
										<thead className="thead-light">
											<tr>
												{/* <th scope="col">Consultant Id</th> */}
												<th scope="col">First Name</th>
												<th scope="col">Last Name</th>
												<th scope="col">Title</th>
												<th scope="col">Email</th>
												<th scope="col">Company</th>
												<th scope="col">Actions</th>
											</tr>
										</thead>
										<tbody>
											{consultants &&
												this.searchResults().map((con, id) => {
													return (
														<tr>
															{/* <th scope="row">
                                <Media className="align-items-center">
                                  <Media>
                                    <span className="mb-0 text-sm">
                                      {con.id}
                                    </span>
                                  </Media>
                                </Media>
                              </th> */}
															<td> {con.first_name}</td>
															<td> {con.last_name}</td>
															<td> {con.title}</td>
															<td> {con.email}</td>
															<td> {this.getCompanyname(con.company).name}</td>
															<td>
																<Button
																	size="sm"
																	color="white"
																	id={`btnReset${id}`}
																>
																	<i
																		className="ni ni-send"
																		onClick={e => {
																			this.props.resetEmailPassword(con.email);
																		}}
																	/>
																</Button>
																<UncontrolledTooltip
																	placement="top"
																	target={`btnReset${id}`}
																>
																	Reset Consultant's Password
																</UncontrolledTooltip>

																<Button
																	onClick={e => {
																		e.preventDefault();
																		this.props.deleteConsultant(con.id);
																	}}
																	size="sm"
																	color="white"
																	id={`btnDel${id}`}
																>
																	<i className="fas fa-trash  " />
																</Button>
																<UncontrolledTooltip
																	placement="top"
																	target={`btnDel${id}`}
																>
																	Delete Consultant
																</UncontrolledTooltip>
																<Button
																	size="sm"
																	onClick={e => {
																		e.preventDefault();
																		this.editToggle(con.id);
																	}}
																	color="white"
																	id={`btnSet${id}`}
																>
																	<i className="ni ni-settings  " />
																</Button>
																<UncontrolledTooltip
																	placement="top"
																	target={`btnSet${id}`}
																>
																	Edit Consultant
																</UncontrolledTooltip>
																<Button
																	onClick={e => {
																		e.preventDefault();
																		if (con.status === "active") {
																			this.props.editConsultant({
																				id: con.id,
																				payload: { status: "deactivated" },
																			});
																		} else {
																			this.props.editConsultant({
																				id: con.id,
																				payload: { status: "active" },
																			});
																		}
																	}}
																	size="sm"
																	color="white"
																>
																	{con.status === "active"
																		? "Deactivate"
																		: "Activate"}
																</Button>

																<Modal
																	isOpen={this.state.editModal === con.id}
																	toggle={this.editToggle}
																>
																	<ModalHeader toggle={this.editToggle}>
																		Edit Consultant
																	</ModalHeader>
																	<ModalBody>
																		<Form onSubmit={this.editConsultant}>
																			<Label>First Name</Label>
																			<Input
																				name="first_name"
																				defaultValue={con.first_name}
																				valid={
																					!this.state.first_name_error &&
																					this.state.first_name
																				}
																				invalid={this.state.first_name_error}
																			/>
																			<FormFeedback>
																				{this.state.first_name_error}
																			</FormFeedback>
																			<Label>Last Name</Label>
																			<Input
																				name="last_name"
																				defaultValue={con.last_name}
																				valid={
																					!this.state.last_name_error &&
																					this.state.last_name
																				}
																				invalid={this.state.last_name_error}
																			/>
																			<FormFeedback>
																				{this.state.last_name_error}
																			</FormFeedback>

																			<Label>Role </Label>
																			<Input
																				name="title"
																				defaultValue={con.title}
																				valid={
																					!this.state.title_error &&
																					this.state.title
																				}
																				invalid={this.state.title_error}
																			/>
																			<FormFeedback>
																				{this.state.title_error}
																			</FormFeedback>
																			<Label>Email</Label>
																			<Input
																				name="email"
																				defaultValue={con.email}
																				valid={
																					!this.state.email_error &&
																					this.state.email
																				}
																				invalid={this.state.email_error}
																			/>
																			<FormFeedback>
																				{this.state.email_error}
																			</FormFeedback>
																			<Label>company</Label>
																			<Input
																				name="company"
																				type="select"
																				valid={
																					!this.state.company_error &&
																					this.state.company
																				}
																				invalid={this.state.company_error}
																			>
																				<option hidden>Company</option>
																				{companies &&
																					companies.map(company => {
																						return (
																							<option
																								value={company.id}
																								selected={
																									con.company === company.id
																								}
																							>
																								{company.name}
																							</option>
																						);
																					})}
																			</Input>
																			<FormFeedback>
																				{this.state.company_error}
																			</FormFeedback>
																			{/* <Label>Email</Label>
                                    <Input
                                      name="email"
                                      type="email"
                                      valid={!this.state.email_error && this.state.email}
                                      invalid={this.state.email_error}
                                    />
                                    <FormFeedback>{this.state.email_error}</FormFeedback> */}

																			{/* <Label>Password</Label>
                                    <Input
                                      name="password"
                                      type="password"
                                      valid={
                                        !this.state.password_error && this.state.password
                                      }
                                      invalid={this.state.password_error}
                                    />
                                    <FormFeedback>{this.state.password_error}</FormFeedback> */}

																			<Row>
																				<Col className="text-right">
																					<Button
																						className=" mt-2"
																						color="default"
																						size="md"
																					>
																						Update Consultant
																					</Button>
																				</Col>
																			</Row>
																		</Form>
																	</ModalBody>
																</Modal>
															</td>
														</tr>
													);
												})}
										</tbody>
									</Table>
								</Card>
							</div>
						</Row>
					</Container>
				</>
			);
		}
	}
}
const mapStateToProps = state => {
	return {
		uid: state.firebase.auth.uid,
		companies: state.firestore.ordered.consulting_companies,
		consultants: state.firestore.ordered.consultants,
	};
};
const mapDispatchToProps = dispatch => {
	return {
		addConsultant: consultant => dispatch(addConsultant(consultant)),
		deleteConsultant: consultant => dispatch(deleteConsultant(consultant)),
		resetEmailPassword: consultant => dispatch(resetEmailPassword(consultant)),
		editConsultant: consultant => dispatch(editConsultant(consultant)),

		// deleteQuestion: (question, assessmentId) =>
		//   dispatch(deleteQuestion(question, assessmentId)),
		// editAssessment: (assessment, assessmentId) =>
		//   dispatch(editAssessments(assessment, assessmentId)),
		// deleteCompany: (company) =>
		//   dispatch(deleteCompany(company)),
		// editCompany: (company, newCompany) =>
		//   dispatch(editCompany(company, newCompany)),
	};
};

export default compose(
	firestoreConnect([
		{ collection: "consulting_companies" },
		{ collection: "consultants" },
	]),
	connect(mapStateToProps, mapDispatchToProps)
)(Consultants);
