import Header from "components/Headers/Header";
import React, { Component } from "react";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { Link, Redirect } from "react-router-dom";
import {
  addQuestionType,
  editQuestionType,
  deleteQuestionType,
} from "actions/componentsActions";

// reactstrap components
import {
  Card,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  Container,
  Row,
  Col,
  Button,
  Form,
  Input,
  CardBody,
  InputGroup,
  InputGroupAddon,
  FormFeedback,
} from "reactstrap";
import { compose } from "redux";

export class QuestionType extends Component {
  state = {
    edit: true, type: "", changeType: "", search: "", pagination: 1,
    QuestionType: 'Ascending',
  };
  assQustionFilters = (arg, mystate) => {
    let resp = []
    let comp_copy = JSON.stringify(this.props.components)
    let parsed_copy = JSON.parse(comp_copy)
    let filtered_arrs = parsed_copy.filter((comp) => comp.id === arg)
    if (filtered_arrs.length > 0) {
      // .QuestionType
      if (this.state[mystate] == 'Ascending') {

        if (filtered_arrs[0].types != null) {

          filtered_arrs[0].types = filtered_arrs[0].types.sort()
          return filtered_arrs
        }
        else {
          return []
        }
      }
      else if (this.state[mystate] == 'Descending') {
        filtered_arrs[0].types = filtered_arrs[0].types.sort().reverse()
        return filtered_arrs
      }
    }
    else {
      return []
    }
  }

  addQuestionType = (e) => {
    e.preventDefault();
    var error = false;
    if (!e.target.question_type.value) {
      this.setState({ question_type_error: "Please add Text" });
      error = true;
    } else {
      this.setState({
        question_type_error: "",
        question_type: e.target.question_type.value,
      });
    }
    if (!error) {
      this.props.addQuestionType(e.target.question_type.value);
      e.target.question_type.value = "";
    }
  };
  deleteQuestionType = (type) => {
    let obj = this.props.assessments.filter(item => item.questions.find(it => it.type == type))
    if (obj.length == 0) {
      this.props.deleteQuestionType(type);
    }
    else {
      alert("This item is currently used  so we cant remove it")
    }
  };
  addToEdit = (type) => {
    this.setState({ type: type, edit: false, changeType: type });
  };
  editQuestionType = (e) => {
    e.preventDefault();

    this.props.editQuestionType(
      this.state.changeType,
      e.target.question_type.value
    );
    this.setState({ edit: true, type: "", changeType: "" });
  };
  search = (e) => {
    e.preventDefault();
    this.setState({ search: e.target.value });
  };

  render() {
    const { uid, components } = this.props;
    if (!uid) {
      return <Redirect to="/auth/login" />;
    } else {
      return (
        <>
          <Header />
          {/* Page content */}
          <Container className="mt--9" fluid>
            <Row>
              <Col>
                <Card className="shadow">
                  <CardBody className=" ">
                    <div style={{ display: "flex" }}>
                      <Link
                        to="/admin/assessmentcomponents"
                        className="text-default "
                      >
                        <i
                          className="fas fa-long-arrow-alt-left "
                          style={{ fontSize: "25px" }}
                        />
                      </Link>
                      <h3 className="ml-3 mb-0 ">Question Type</h3>
                    </div>
                    <p className="text-info">Used to determine Question Type</p>
                    <Table
                      className="align-items-center  table-flush mt-4"
                      responsive
                    >
                      <thead className="thread-light">
                        <tr>
                          <th scope="col"> Id</th>
                          <th scope="col">Type</th>
                        </tr>
                      </thead>
                      <tbody>
                        {components &&
                          this.assQustionFilters("question_type", 'QuestionType')
                            .filter((comp) => comp.id === "question_type")
                            .map((comp) => {
                              return comp.types
                                .filter((type) => {
                                  if (this.state.search) {
                                    return type.includes(this.state.search);
                                  } else {
                                    return true;
                                  }
                                })
                                .splice(5 * (this.state.pagination - 1), 5)

                                .map((type, i) => {
                                  return (
                                    <tr hidden={this.state.changeType === type}>
                                      <th scope="row">
                                        <span className="mb-0 text-sm ">
                                          {i + 1}
                                        </span>
                                      </th>
                                      <td className=""> {type}</td>

                                    </tr>
                                  );
                                });
                            })}
                      </tbody>
                    </Table>
                    <Row className="mt-2">
                      <Col md={2} xs={12}>
                        <Pagination aria-label="Page navigation example">
                          <PaginationItem>
                            <PaginationLink
                              className=" "
                              previous
                              disabled={this.state.pagination === 1}
                              onClick={(e) => {
                                e.preventDefault();
                                this.setState({
                                  pagination: this.state.pagination - 1,
                                });
                              }}
                            />
                          </PaginationItem>
                          <PaginationItem>
                            <PaginationLink className=" " disabled>
                              {this.state.pagination}
                            </PaginationLink>
                          </PaginationItem>
                          <PaginationItem>
                            <PaginationLink
                              className=" "
                              next
                              disabled={
                                components &&
                                components
                                  .filter(
                                    (comp) => comp.id === "question_type"
                                  )[0]
                                  .types.filter((type) => {
                                    if (this.state.search) {
                                      return type.includes(this.state.search);
                                    } else {
                                      return true;
                                    }
                                  }).length /
                                5 <=
                                this.state.pagination
                              }
                              onClick={(e) => {
                                e.preventDefault();
                                this.setState({
                                  pagination: this.state.pagination + 1,
                                });
                              }}
                            />
                          </PaginationItem>
                        </Pagination>
                      </Col>
                      <Col md={8} xs={12} className="text-left">
                        <sub>Page {this.state.pagination} of <span >{components && (Math.ceil(Math.floor(components.filter((comp) => comp.id === "question_type")[0].types.length) / 5))}</span>
                        </sub>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </>
      );
    }
  }
}
const mapStateToProps = (state) => {
  return {
    uid: state.firebase.auth.uid,
    components: state.firestore.ordered.components,
    assessments: state.firestore.ordered.assessments

  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addQuestionType: (type) => dispatch(addQuestionType(type)),
    deleteQuestionType: (type) => dispatch(deleteQuestionType(type)),
    editQuestionType: (type, newType) =>
      dispatch(editQuestionType(type, newType)),
  };
};

export default compose(
  firestoreConnect(["assessments", "components"]),
  connect(mapStateToProps, mapDispatchToProps)
)(QuestionType);
