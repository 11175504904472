import { editLanguage } from 'actions/languageActions';
import { addLanguage, deleteLanguage } from 'actions/languageActions';
import Header from 'components/Headers/Header';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { firestoreConnect, isEmpty, isLoaded } from 'react-redux-firebase';
import { Link } from 'react-router-dom';
import {
	ButtonGroup,
	Card,
	CardHeader,
	Container,
	Row,
	Spinner,
	Table,
	Modal,
	ModalBody,
	ModalHeader,
	Form,
	FormGroup,
	Label,
	Input,
	Button,
} from 'reactstrap';
import { compose } from 'redux';
class Language extends Component {
	state = {
		modals: {
			add: false,
			edit: false,
		},
	};
	toggleModal = name => {
		let { modals } = JSON.parse(JSON.stringify(this.state));
		modals[name] = !modals[name];
		this.setState({
			modals,
		});
	};
	render() {
		let { languages } = this.props;
		return (
			<>
				<>
					<Header />
					{/* Page content */}
					<Container className='mt--9' fluid>
						{/* forms */}
						<div style={{ display: 'flex' }}>
							<span className='ml-auto mb-2'>
								<Link
									className='btn btn-white'
									onClick={e => {
										e.preventDefault();
										this.toggleModal('add');
									}}
								>
									Add Language
								</Link>
							</span>
						</div>

						<Row>
							<div className='col'>
								<Card className='shadow'>
									<CardHeader className='border-0'>
										<h3 className='mb-0'>Language</h3>
									</CardHeader>
									{!isLoaded(languages) ? (
										<div className='d-flex align-items-center justify-content-center my-5'>
											<Spinner />
										</div>
									) : isEmpty(languages) ? (
										<div className='d-flex align-items-center justify-content-center my-5'>
											No Record Yet
										</div>
									) : (
										<Table responsive>
											<thead>
												<tr>
													<th>SR</th>
													<th>Language</th>
													<th>Action</th>
												</tr>
											</thead>
											<tbody>
												{languages.map(
													(language, idx) => (
														<tr>
															<th role='row'>
																{idx + 1}
															</th>
															<td className='text-capitalize'>
																{
																	language.language
																}
															</td>
															<td>
																<ButtonGroup>
																	<Button
																		size='sm'
																		color='primary'
																		onClick={async e => {
																			e.preventDefault();
																			await this.setState(
																				{
																					id:
																						language.id,
																					language:
																						language.language,
																				}
																			);
																			await this.toggleModal(
																				'edit'
																			);
																		}}
																	>
																		<i className='fa fa-edit'></i>
																	</Button>
																	<Button
																		size='sm'
																		color='danger'
																		onClick={e => {
																			this.props.deleteLanguage(
																				language.id
																			);
																		}}
																	>
																		<i className='fa fa-trash'></i>
																	</Button>
																</ButtonGroup>
															</td>
														</tr>
													)
												)}
											</tbody>
										</Table>
									)}
								</Card>
							</div>
						</Row>
					</Container>
				</>
				<Modal
					isOpen={this.state.modals.add}
					toggle={e => this.toggleModal('add')}
				>
					<ModalHeader toggle={e => this.toggleModal('add')}>
						Add Language
					</ModalHeader>
					<ModalBody>
						<Form
							onSubmit={e => {
								e.preventDefault();
								this.props.addLanguage(e.target.language.value);
								this.toggleModal('add');
							}}
						>
							<FormGroup>
								<Label>Language</Label>
								<Input
									type='text'
									name='language'
									required
								></Input>
							</FormGroup>
							<Button
								color='primary'
							>
								Save
							</Button>
						</Form>
					</ModalBody>
				</Modal>
				<Modal
					isOpen={this.state.modals.edit}
					toggle={e => this.toggleModal('edit')}
				>
					<ModalHeader toggle={e => this.toggleModal('edit')}>
						Edit Language
					</ModalHeader>
					<ModalBody>
						<Form
							onSubmit={e => {
								e.preventDefault();
								this.props.editLanguage(
									e.target.language.value,
									e.target.id.value
								);
								this.toggleModal('edit');
							}}
						>
							<Input
								type='hidden'
								value={this.state.id}
								name='id'
							/>
							<FormGroup>
								<Label>Language</Label>
								<Input
									type='text'
									name='language'
									required
									defaultValue={this.state.language && this.state.language.replace(
										/\b\w/g,
										l => l.toUpperCase()
									)}
								></Input>
							</FormGroup>
							<Button
								color='primary'
							>
								Save
							</Button>
						</Form>
					</ModalBody>
				</Modal>
			</>
		);
	}
}

const mapStateToProps = state => {
	return {
		languages: state.firestore.ordered.languages,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		addLanguage: language => dispatch(addLanguage(language)),
		editLanguage: (language, id) => dispatch(editLanguage(language, id)),
		deleteLanguage: id => dispatch(deleteLanguage(id)),
	};
};

export default compose(
	firestoreConnect([{ collection: 'languages' }]),
	connect(mapStateToProps, mapDispatchToProps)
)(Language);
