/*eslint-disable*/
import React from "react";
import { NavLink as NavLinkRRD, Link } from "react-router-dom";
// nodejs library to set properties for components
import { PropTypes } from "prop-types";
import { logout } from "../../actions/authActions";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";

// reactstrap components
import {
	Button,
	Collapse,
	NavbarBrand,
	Navbar,
	NavItem,
	NavLink,
	Nav,
	Container,
	Row,
	Col,
	UncontrolledCollapse,
} from "reactstrap";

var ps;

class Sidebar extends React.Component {
	state = {
		collapseOpen: false,
	};
	constructor(props) {
		super(props);
		this.activeRoute.bind(this);
	}
	// verifies if routeName is the one active (in browser input)
	activeRoute(routeName) {
		return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
	}
	// toggles collapse between opened and closed (true/false)
	toggleCollapse = () => {
		this.setState({
			collapseOpen: !this.state.collapseOpen,
		});
	};
	// closes the collapse
	closeCollapse = () => {
		this.setState({
			collapseOpen: false,
		});
	};
	// creates the links that appear in the left menu / Sidebar
	createLinks = routes => {
		return routes.map((prop, key) => {
			if (prop.menu !== false)
				return (
					<NavItem key={key}>
						<NavLink
							to={prop.layout + prop.path}
							tag={NavLinkRRD}
							onClick={() => {
								this.props.history.push(
									prop.layout + prop.path
								);
							}}
							activeClassName='active'
							id={`toggler${key}`}
						>
							<i className={prop.icon} />
							{prop.name}
							{prop.dropdown && (
								<div className='ml-auto'>
									<i className='fas fa-eye text-muted'></i>
								</div>
							)}
						</NavLink>
						{prop.dropdown &&
							prop.dropdown.length > 0 &&
							prop.dropdown.map((cprop, ckey) => (
								<UncontrolledCollapse
									toggler={`#toggler${key}`}
									key={ckey}
								>
									<NavItem
										key={`c${key}${ckey}`}
										style={{ marginLeft: '36px' }}
									>
										<NavLink
											to={
												prop.layout +
												prop.path +
												cprop.path
											}
											tag={NavLinkRRD}
											activeClassName='active'
										>
											{cprop.name}
										</NavLink>
									</NavItem>
								</UncontrolledCollapse>
							))}
					</NavItem>
				);
		});
	};
	render() {
		const { bgColor, routes, logo } = this.props;
		let navbarBrandProps;
		if (logo && logo.innerLink) {
			navbarBrandProps = {
				to: logo.innerLink,
				tag: Link,
			};
		} else if (logo && logo.outterLink) {
			navbarBrandProps = {
				href: logo.outterLink,
				target: "_blank",
			};
		}
		return (
			<Navbar
				className="navbar-vertical fixed-left navbar-light bg-white"
				expand="md"
				id="sidenav-main"
			>
				<Container fluid>
					{/* Toggler */}
					<button
						className="navbar-toggler"
						type="button"
						onClick={this.toggleCollapse}
					>
						<span className="navbar-toggler-icon" />
					</button>
					{/* Brand */}
					{logo ? (
						<NavbarBrand className="p-0" {...navbarBrandProps}>
							<img
								alt={logo.imgAlt}
								className="navbar-brand-img"
								src={require("assets/img/brand/5131_Integral_Agile_Institute_R_S_logo_01.png")}
							/>
							<div className="text-center">
								<h4 className="mt-2">Admin Panel</h4>
							</div>
						</NavbarBrand>
					) : null}
					{/* User */}
					<Nav className="align-items-center d-md-none">
						<Button
							onClick={e => {
								this.props.logout();
							}}
						>
							Logout
						</Button>
						{/* <UncontrolledDropdown nav>
              <DropdownToggle nav>
                <Media className="align-items-center">
                  <span className="avatar avatar-sm rounded-circle">
                    <img
                      alt="..."
                      src={require("assets/img/theme/dummy-avatar.jpg")}
                    />
                  </span>
                </Media>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                  <DropdownItem  onClick={(e) => {
                      e.preventDefault();
                      this.props.logout();
                    }}>
                    <i className="ni ni-user-run" />
                    <span>Logout</span>
                  </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown> */}
					</Nav>
					{/* Collapse */}
					<Collapse navbar isOpen={this.state.collapseOpen}>
						{/* Collapse header */}
						<div className="navbar-collapse-header d-md-none">
							<Row>
								{logo ? (
									<Col className="collapse-brand" xs="6">
										{logo.innerLink ? (
											<Link to={logo.innerLink}>
												<img
													alt={logo.imgAlt}
													src={require("assets/img/brand/5131_Integral_Agile_Institute_R_S_logo_01.png")}
												/>
											</Link>
										) : (
											<a href={logo.outterLink}>
												<img alt={logo.imgAlt} src={logo.imgSrc} />
											</a>
										)}
									</Col>
								) : null}
								<Col className="collapse-close" xs="6">
									<button
										className="navbar-toggler"
										type="button"
										onClick={this.toggleCollapse}
									>
										<span />
										<span />
									</button>
								</Col>
							</Row>
						</div>
						{/* Navigation */}
						<Nav navbar>{this.createLinks(routes)}</Nav>
					</Collapse>
				</Container>
			</Navbar>
		);
	}
}

Sidebar.defaultProps = {
	routes: [{}],
};

Sidebar.propTypes = {
	// links that will be displayed inside the component
	routes: PropTypes.arrayOf(PropTypes.object),
	logo: PropTypes.shape({
		// innerLink is for links that will direct the user within the app
		// it will be rendered as <Link to="...">...</Link> tag
		innerLink: PropTypes.string,
		// outterLink is for links that will direct the user outside the app
		// it will be rendered as simple <a href="...">...</a> tag
		outterLink: PropTypes.string,
		// the image src of the logo
		imgSrc: PropTypes.string.isRequired,
		// the alt for the img
		imgAlt: PropTypes.string.isRequired,
	}),
};

const mapStateToProps = state => {
	return {
		uid: state.firebase.auth.uid,
		users: state.firestore.ordered.users,
	};
};
const mapDispatchToProps = dispatch => {
	return { logout: () => dispatch(logout()) };
};

export default compose(
	firestoreConnect([{ collection: "users" }]),
	connect(mapStateToProps, mapDispatchToProps)
)(Sidebar);
