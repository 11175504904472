export const createTemplate = templateObj => (
	dispatch,
	getState,
	{ getFirebase, getFirestore }
) => {
	const firestore = getFirestore();
    firestore.collection("templates").add(templateObj).then(()=>{
		dispatch ({
			type:"CREATED_TEMPLATE",
		});
	});
};
export const editTemplate = templateObj => (
	dispatch,
	getState,
	{ getFirebase, getFirestore }
) => {
	const firestore = getFirestore();
	let id = templateObj.template_id;
	delete templateObj.template_id;
	firestore
		.collection('templates')
		.doc(id).update(templateObj)
		.then(() => {
			dispatch({
				type: 'EDIT_TEMPLATE',
			});
		});
};

export const resetCreated = () =>{
	return({
		type:"RESET_CREATED"
	})
}

export const deleteTemplate = (id) => (
	dispatch,
	getState,
	{ getFirebase, getFirestore }
) => {
	const firestore = getFirestore();
	firestore.collection("templates").doc(id).delete().then(()=>{
		
	})
	
}