export const addLanguage = language => async (
	dispatch,
	getState,
	{ getFirebase, getFirestore }
) => {
	let firestore = getFirestore();
	let data = await firestore
		.collection('languages')
		.where('language', '==', language.toLowerCase())
		.get();
	let isAlready = false;
	for (let doc of data.docs) {
		isAlready = true;
	}
	if (!isAlready) {
		firestore
			.collection('languages')
			.add({ language: language.toLowerCase() })
			.then(() => {});
	}
};
export const editLanguage = (language,id) => async (
	dispatch,
	getState,
	{ getFirebase, getFirestore }
) => {
	let firestore = getFirestore();
		firestore
			.collection('languages')
            .doc(id)
			.update({ language: language.toLowerCase() })
			.then(() => {});
};
export const deleteLanguage = (id) => async (
	dispatch,
	getState,
	{ getFirebase, getFirestore }
) => {
	let firestore = getFirestore();
	firestore
		.collection('languages')
		.doc(id)
		.delete()
		.then(() => {});
};

