import React, { Component, useEffect, useState } from "react";
import { connect } from "react-redux";
import { firestoreConnect, getFirebase, useFirebase } from "react-redux-firebase";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { Spinner } from "reactstrap";
import { compose } from "redux";
import AdminLayout from "./layouts/Admin";
import AuthLayout from "./layouts/Auth";


function App() {
	const [isReady, setIsReady] = useState(false)

	useEffect(() => {
		getFirebase().auth().onAuthStateChanged(user => {
			setIsReady(true)
		})
	}, [])

	if (!isReady) return (
		<div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "100vh" }} >
			<Spinner color="primary" />
		</div>
	)
	return <BrowserRouter>
		<Switch>
			<Route
				path="/admin"
				render={props => (
					<div>
						<AdminLayout {...props} />
					</div>
				)}
			/>
			<Route
				path="/auth"
				render={props => (
					<div>
						<AuthLayout {...props} />
					</div>
				)}
			/>
			<Redirect from="/" to="/auth/login" />
		</Switch>
	</BrowserRouter>
}


const mapStateToProps = state => {
	return {
		uid: state.firebase.auth.uid,
	};
};

export default compose(
	firestoreConnect(() => [
		{ collection: "users" },
		{ collection: "products" },
		{ collection: "reviews" },
		{ collection: "announcements" },
	]),
	connect(mapStateToProps)
)(App);
