export const login = credentials => {
	return (dispatch, getState, { getFirebase, getFirestore }) => {
		dispatch({
			type: "IS_LOADING",
		});
		const firebase = getFirebase();
		const firestore = getFirestore();
		firebase
			.auth()
			.signInWithEmailAndPassword(credentials.email, credentials.password)
			.then(data => {
				return data.user.uid;
			})
			.then(uid => {
				return firestore.collection("users").doc(uid).get();
			})
			.then(data => {
				dispatch({
					type: "IS_LOADED",
				});
				if (data.data() === undefined) {
					dispatch({
						type: "LOGIN_FAIL",
						id_type: "",
						uid: "",
						error: { message: "You are not authorized to access this panel." },
					});
				} else {
					dispatch({
						type: "LOGIN_SUCCESS",
						id_type: data.data().role,
						uid: data.id,
						error: "",
					});
				}
			})
			.catch(error => {
				console.log(error);

				dispatch({
					type: "IS_LOADED",
				});
				dispatch({
					type: "LOGIN_FAIL",
					id_type: "",
					uid: "",
					error: error,
				});
			});
	};
};
export const logout = () => {
	return (dispatch, getState, { getFirebase, getFirestore }) => {
		const firebase = getFirebase();
		firebase
			.auth()
			.signOut()
			.then(data => {
				localStorage.clear();
				dispatch({
					type: "LOGOUT_SUCCESS",
					id_type: "",
					uid: "",
					signup: false,
					error: "",
				});
			});
	};
};
