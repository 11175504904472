import React from "react";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { Link } from "react-router-dom";
// reactstrap components
import {
  Button,
  Container, Nav, Navbar
} from "reactstrap";
import { compose } from "redux";
import { logout } from "../../actions/authActions";

class AdminNavbar extends React.Component {
  render() {
    const { uid, users } = this.props;
    const thisUser = users && users.filter((user) => user.id === uid)[0];
    return (
      <>
        <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
          <Container fluid>
            <Link
              className="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block"
              to="/"
            >
              {this.props.brandText}
            </Link>
            <Nav className="align-items-center d-none d-md-flex" navbar>
              <Button onClick={(e) => {
                this.props.logout();
              }}>Logout</Button>
              {/* <UncontrolledDropdown nav> */}
              {/* <DropdownToggle className="pr-0" nav> */}
              {/* <Media className="align-items-center"> */}
              {/* <span className="avatar avatar-sm rounded-circle"> */}
              {/* <img
                        alt="..."
                        src={require("assets/img/theme/dummy-avatar.jpg")}
                      /> */}
              {/* </span> */}
              {/* <Media className="ml-2 d-none d-lg-block">
                      <span className="mb-0 text-sm font-weight-bold">
                        {thisUser && thisUser.role === "super admin"
                          ? "Super Admin"
                          : ""}
                        {thisUser && thisUser.role === "admin"
                          ? thisUser.name
                          : ""}
                      </span>
                    </Media> */}
              {/* </Media>
                </DropdownToggle> */}
              {/* <DropdownMenu className="dropdown-menu-arrow" right>
                  <DropdownItem
                    onClick={(e) => {
                      e.preventDefault();
                      this.props.logout();
                    }}
                  >
                    <i onClick={(e) => {
                      e.preventDefault();
                      this.props.logout();
                    }} className="ni ni-user-run" />
                    <span>Logout</span>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown> */}
            </Nav>
          </Container>
        </Navbar>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    uid: state.firebase.auth.uid,
    users: state.firestore.ordered.users,
  };
};
const mapDispatchToProps = (dispatch) => {
  return { logout: () => dispatch(logout()) };
};

export default compose(
  firestoreConnect([{ collection: "users" }]),
  connect(mapStateToProps, mapDispatchToProps)
)(AdminNavbar);
